.salon-de-la-fama {
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;

    &__title {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__competition {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        margin-bottom: 10px;
        padding: 10px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &__item {
        display: flex;
        gap: 8px;
    }
}