@use 'mixins';
@use 'variables';

.only-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.only-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
}

[id^='div-gpt-ad'] {
    display: flex;
    justify-content: center;
    align-items: center;
}

.publift-ads {
    @include mixins.center-content-flex;
    padding: 5px 0;
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;
}

.publift-ads-header {
    min-height: 100px;
}

.publift-ads-flash-incontent-5,
.publift-ads-flash-incontent-6 {
    @media (max-width: variables.$breakpoint-md) {
        justify-content: flex-start;
    }
}

.publift-ads-mobile-incontent,
.publift-ads-mobile-incontent-1 {
    @media screen and (min-width: variables.$breakpoint-sm) {
        display: none;
    }
}

.publift-ads-flash-incontent-5,
.publift-ads-flash-incontent-6 {
    @media (max-width: variables.$breakpoint-md) {
        justify-content: flex-start;
    }
}

.publift-ads-mobile-incontent,
.publift-ads-mobile-incontent-1 {
    @media screen and (min-width: variables.$breakpoint-sm) {
        display: none;
        min-height: 0;
    }
}

.top-website-container {
    display: none !important;
}

.videoo-ancla-container,
.gpt-ancla-container {
    display: none !important;
}

.adsense-responsive-container {
    &__text {
        display: none;
    }
}

#gpt_unit_\/21682672808\,22651342849\/analiticafantasy\.com\/analiticafantasy\.com_catfish_0 {
    display: none !important;
}

#div-gpt-ad-1407836037948-5 {
    margin-top: 16px;
}

.url-iniciar-sesion,
.url-crear-cuenta {
    #div-gpt-ad-1407836240192-0 {
        display: none;
    }

    .lazy-ad-unit {
        min-height: 0;
    }
}

.main-ad-unit {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

[id^='af_digitalgreen'] {
    display: flex;
    justify-content: center;
    align-items: center;
}

#div-gpt-ad-yieldlove-sticky-35139 {
    display: none !important;
    position: relative !important;
    width: 0 !important;
    height: 0 !important;
}

.analitica-fantasy-top-ad {
    min-height: 250px;
}

.placement-ad-template {
    min-height: 250px;
}

.analitica-free {
    .ad-unit-head {
        min-height: 250px;
    }

    .hb-ad-a320x100 {
        min-height: 110px;
    }

    .hb-container {
        display: flex;
        justify-content: center;
        align-items: center;

        .hb-ad-inner {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .hb-ad-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .money-ad-parent {
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .money-ad-20,
        .money-ad-3 {
            min-height: 600px;
        }

        .money-ad-31 {
            min-height: 250px;
        }

        .money-ad-generic {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .hb-min-250 {
        min-height: 250px;
    }

    .adsense-responsive-container {
        min-height: 300px !important;
        width: 100% !important;
        margin-bottom: 8px;
        text-align: center;

        &__text {
            font-size: 12px;
            display: flex;
            justify-content: flex-end;
            color: #80868b;
        }
    }

    #div-gpt-ad-1407836169909-0,
    #div-gpt-ad-1407836240192-0 {
        min-height: 250px;
    }

    .lazy-ad-unit {
        min-height: 250px;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .adsbygoogle-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        width: 100%;
        min-width: 320px;
        height: 100px;
        position: sticky;
        top: 100px;
    }

    .af-adsense-ad-text {
        font-size: 12px;
    }

    .adsbygoogle {
        margin: auto;
    }

    .af-digitalgreen-parent {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .af-digitalgreen-parent {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
        margin-bottom: 16px;

        .af-digitalgreen-ad {
            width: 100%;
        }

        .af-digitalgreen-ad-text {
            height: 15px;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            color: #000;
        }
    }

    .af-digitalgreen-parent_4 {
        height: 115px !important;
        min-height: 115px !important;

        @media screen and (min-width: variables.$breakpoint-sm) {
            min-height: 265px !important;
        }

        .af-digitalgreen-ad {
            height: 100px !important;
            min-height: 100px !important;
        }
    }

    .videoo-ancla-container,
    .gpt-ancla-container {
        position: fixed;
        bottom: -1px;
        width: 100%;
        height: 115px;
        background-color: #f9f9f9;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        z-index: 9999;

        @media screen and (min-width: variables.$breakpoint-sm) {
            display: none;
        }
    }

    .ad-slot,
    .gpt-ancla-slot {
        height: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ad-label,
    .gpt-ad-label {
        font-size: 10px;
        color: #000;
        text-align: center;
        height: 15px;
        /* Espacio para el texto */
    }

    .placement-ad-template-bottom {
        min-height: 120px;
    }
}

.analitica-premium {
    .analitica-fantasy-top-ad {
        min-height: 0;
        height: 0 !important;
    }
    .publift-ads-header {
        min-height: 0;
    }

    .placement-ad-template {
        overflow-x: auto;
        height: 0;
        display: none;
        min-height: 0;
    }

    .hb-min-250 {
        min-height: 0 !important;
    }

    .af-adsense-ad-text {
        display: none;
    }

    .af-digitalgreen-ad-text {
        display: none !important;
    }

    .gpt-ancla-container {
        display: none;
    }

    .adsbygoogle {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
    }
}

.HB_Footer_Close_hbagency_space_191500 {
    display: none;
}
