@use "../shared/variables";

.analisis-page {
    @media screen and (max-width: variables.$breakpoint-sm) {
        width: 100%;
        padding-right: 5px;
        padding-left: 5px;
    }
}

.plantilla-jugador-container {
    margin-bottom: 25px;

    .proximo-rival-icon-visitor {
        top: -2px;
        margin-left: 8px;
    }

    .proximo-rival-icon-visitor-container {
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
    }

    .fs-14-px {
        font-size: 14px;
    }
}

.data-all-seasons-text {
    font-size: 12px;
    float: left;
    margin-top: 5px;
    margin-bottom: -5px;

    .fas.fa-info-circle {
        margin-right: 2px;
    }
}

.plantilla-jugador-container__box {
    border: 1px solid variables.$color-border;
    border-radius: 4px;
    width: 100%;
    min-height: 210px;
    font-size: 14px;
    padding: 10px;

    .proximo-rival {
        border-bottom: 1px solid variables.$color-border;
        border-top: 1px solid variables.$color-border;
        padding-top: 5px;
    }

    .proximo-rival-icon-local-container {
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        margin-right: 15x;
    }

    h6 {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 0;

        & + span {
            position: relative;
            bottom: 3px;
        }
    }

    .image-card-table {
        border-radius: 50%;
        margin-right: 8px;
    }

    .image-card-table-1 {
        border: 2px solid #1d6fb1 !important;
    }

    .image-card-table-2 {
        border: 2px solid #1a892d !important;
    }

    .image-card-table-3 {
        border: 2px solid #812c7c !important;
    }

    .image-card-table-4 {
        border: 2px solid #be1212 !important;
    }

    .image-card-table-nickname {
        top: 10px;
        font-size: 15px;
    }

    .proximo-rival-local-container {
        display: flex;
    }

    .proximo-rival-icon-local {
        color: rgba(0, 0, 0, 0.7);
        width: 28px;
        height: 28px;
        border-radius: 4px;
        position: relative;
        top: -2.5px;
        left: -2px;
    }

    .jugadores-position-container {
        padding: 2px 0px 2px 0px;
    }

    .position-color-red {
        width: 29px;
    }

    .position-color-red.jugadores-position-container-super {
        float: right;
    }
}

.analisis-plantilla-card-line-container__content {
    width: 100%;
}

.analisis-plantilla-card-line-container__content__line-risk {
    display: inline-flex;
    width: 100%;

    h6 {
        margin-right: 5px;
    }
}

h6.analisis-plantilla-card-line-container__content__line-rival {
    &::before {
        content: '\2022';
        margin-right: 2px;
        font-weight: normal;
    }
}

.analisis-plantilla-card-line-container__content__line-risk-rival {
    display: inline-flex;
    width: 100%;

    &::before {
        content: '-';
        margin-right: 2px;
        font-weight: normal;
    }
}

.analisis-plantilla-card-line-proximo-rival {
    margin-right: 7px;
}

.analisis-plantilla-card-line-container {
    display: inline-flex;

    .analisis-plantilla-card-line-container__content {
        border-bottom: 1px solid variables.$color-border;
        width: 100%;
        padding-top: 5px;

        .analisis-plantilla-card-line-container__content__line {
            display: inline-flex;
            width: fit-content;

            h6 {
                &::before {
                    content: '\2022';
                    margin-right: 2px;
                    font-weight: normal;
                }
            }
        }

        .analisis-plantilla-card-line-container__content__line-risk {
            display: inline-flex;
            width: 100%;

            h6 {
                &::before {
                    content: '-';
                    margin-right: 2px;
                    font-weight: normal;
                }
            }
        }
    }

    .analisis-plantilla-card-line {
        margin-right: 7px;
    }
}

@media screen and (max-width: 600px) {
}

table.analiza-tu-plantilla-new-table {
    width: 100%;
    border: 1px solid variables.$color-border;
    padding: 2px;
    border-radius: 4px;

    thead {
        th:first-child {
            padding-left: 10px;
        }
    }

    tr {
        height: 35px;
        font-size: 14px;

        td:first-child {
            padding-left: 4px;
        }

        td,
        th {
            font-size: 14px;
        }
    }

    tbody {
        tr:nth-child(odd) {
            background: #f7f7f7;
        }
    }
}

.all-team-info-container {
    padding: 0;
    max-height: 550px;
}

@media screen and (max-width: 600px) {
    table.analiza-tu-plantilla-new-table {
        tr {
            font-size: 12px;
        }
    }

    .all-team-info-container {
        max-height: 460px;
    }
}

.new-template-link {
    // @media screen and (min-width: 600px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // }
}

.nueva-plantillas-btn-container {
    .nueva-plantillas-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 600px) {
            width: 100%;
        }
    }

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.armador-container {
    .armador-container__content-container {
        margin: auto;
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 25px;
    }

    #valoracion-plantilla {
        padding-left: 10px;
        padding-right: 10px;
    }

    margin-bottom: 20px;
}

.stadium-container {
    position: relative;
    display: grid;
    height: 600px;
    background: url('https://assets.analiticafantasy.com/estadio/estadio.png?width=800');
    width: 100%;
    background-size: 100% 100%;
    margin-bottom: 25px;
    padding-left: 5px;
    padding-right: 5px;

    .stadium__image {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 4px;
    }

    .players-line {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .players-line-delanteros {
        margin-bottom: 0;
    }

    .players-line-portero {
        margin-bottom: 0px !important;
        justify-content: center;
    }

    .players-line-0-container {
        margin-bottom: 0px !important;
        justify-content: center;
    }

    .player-line {
        width: 100%;
        // padding-left: 5%;
    }
}

.armador-image-cards__placeholder {
    margin: auto;
    border-radius: 4px;
    width: 50px;
    height: 50px !important;
    -webkit-box-shadow: 3px 0px 26px 0px rgba(0, 170, 141, 1);
    -moz-box-shadow: 3px 0px 26px 0px rgba(0, 170, 141, 1);
    box-shadow: 3px 0px 26px 0px rgba(0, 170, 141, 1);
    border-radius: 2px;
    font-size: 12px;
    padding: 0px 5px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    background: #fff;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.player-card-team-image {
    position: absolute;
    top: 0px;
    right: 4px;
    width: 22px;

    @media (max-width: 968px) {
        top: 12px;
    }
}

.player-card-total-score {
    border-left: 21px solid #0172aa;
    border-top: 0 solid transparent;
    border-bottom: 30px solid transparent;
    position: absolute;
    top: 0;
    z-index: 0;
    cursor: pointer;
    height: 20px;
    position: absolute;
    left: 0;

    label {
        position: absolute;
        top: -2px;
        left: -19px;
        color: #fff;
        font-size: 11px;
    }
}

.armador-player-card {
    position: relative;
    width: 70px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__team-image {
        position: absolute;
        top: -30px;
        right: -12px;

        @media (min-width: 968px) {
            top: 0;
            right: -8px;
        }
    }

    .armador-player-card__caption {
        border-radius: 2px;
        font-size: 12px;
        padding: 0px 5px;
        width: 60px;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        background: #fff;
        overflow-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
}

.armador-image-cards {
    width: 50px;
    height: 50px;
}

.alineacion-container {
    width: 100%;
    margin: auto;
    display: inline-flex;

    margin-top: 15px;

    h2 {
        font-size: 16px;
        position: relative;
        top: 6px;
    }
}

.selector-alineacion-container {
    margin-bottom: 25px;
    width: 100%;
}

.jugadores-header {
    width: fit-content;
    font-weight: normal;
}

.reiniciar-alineacion-button {
    color: #fff;
    background: variables.$warning-color;
    border-radius: 4px;
    padding: 5px 15px;
    width: fit-content;
    font-size: 14px;
    top: 8px;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

.valoracion-mercado-button {
    color: #fff;
    background: variables.$position-df-color;
    border-radius: 4px;
    padding: 5px 15px;
    width: fit-content;
    font-size: 14px;
    top: 8px;
    margin-left: 55px;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

.mis-plantillas-actions-container {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .mis-plantillas-actions-subcontainer {
        display: flex;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 961px) {
    .valoracion-mercado-button,
    .reiniciar-alineacion-button,
    .descargar-plantilla-button {
        width: 100% !important;
        margin-bottom: 15px;
        text-align: center;
    }

    .valoracion-mercado-button {
        margin-left: 0;
    }

    .mis-plantillas-actions-container {
        display: flex;
        flex-direction: column;

        .mis-plantillas-actions-subcontainer {
            display: flex;
            flex-direction: column;
        }
    }

    .alineacion-selector-text-container {
        padding-right: 0;
    }
}

.position-info-container {
    margin-bottom: 4px;
    padding-left: 10px;
    border-radius: 4px;

    h6 {
        font-size: 22px;
        border-bottom: 1px solid variables.$color-border;
        padding-bottom: 5px;
    }
}

.total-info-container {
    div {
        div.armador-player-selected-card {
            border-bottom: none;
        }
    }
}

div.armador-player-selected-card {
    display: inline-flex;
    width: 100%;

    border-bottom: 1px solid variables.$color-border;
    border-radius: 4px;
    padding-bottom: 4px;
    padding-top: 4px;

    .armador-player-selected-card__nickname {
        width: 33%;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 5px;
    }

    .armador-player-selected-card__price {
        width: 25%;
        text-align: right;
        color: rgba(0, 0, 0, 0.8);
        font-size: 12px;
    }

    .armador-player-selected-card__psfy {
        width: 20%;
        text-align: right;
        font-size: 12px;
    }

    .armador-player-selected-card__media {
        width: 20%;
        text-align: right;
        font-size: 12px;
    }
}

.modal-header-title-close-container {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.player-selected-card-green {
    background-color: #1a892d;
    border-radius: 4px;
    color: #fff;
    padding: 3px 4.5px 5px 4.5px;
    margin-right: 8px;
    font-size: 10px;
    height: 20px;
}

.player-selected-card-red {
    background-color: #be1212;
    border-radius: 4px;
    color: #fff;
    padding: 3px 4.5px 5px 4.5px;
    margin-right: 8px;
    font-size: 10px;
    height: 20px;
}

.player-selected-card-blue {
    background-color: #1d6fb1;
    border-radius: 4px;
    color: #fff;
    padding: 3px 4.5px;
    margin-right: 8px;
    font-size: 10px;
    height: 20px;
}

.player-selected-card-purple {
    background-color: #812c7c;
    border-radius: 4px;
    color: #fff;
    padding: 3px 4.5px 5px 4.5px;
    margin-right: 8px;
    font-size: 10px;
    height: 20px;
}

.container-info-page {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 0;

    p {
        padding-top: 15px;
        padding-left: 15px;
        padding-bottom: 15px;
        padding-right: 15px;
        font-size: 18px;
        border: 1px solid variables.$color-border;
        border-radius: 4px;
    }
}

.descargar-plantilla-button {
    width: fit-content;
    padding: 5px 15px;
    color: #fff;
    background: #1d6fb1;
    border-radius: 4px;
    font-size: 14px;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

.my-twitter-user {
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

@media screen and (min-width: 961px) {
    .armador-container {
        margin-bottom: 0px;
        min-height: calc(100vh - 180px);
    }

    .descargar-plantilla-button {
        margin-left: 10px;
    }

    .container-info-page {
        padding-right: 0px;
        padding-left: 15px;
    }

    .alineacion-selector-text-container {
        padding-left: 15px;
    }

    .jugadores-header {
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid variables.$color-border;
        padding-bottom: 5px;
        width: 100%;
        font-size: 20px;
    }

    .crea-tu-once-header {
        position: relative;
        left: 15px;
    }
}

.message-text {
    font-size: 12px;
    margin-bottom: 5px;
}

@media screen and (max-width: 960px) {
    .crea-tu-once-header {
        margin-top: 15px;
    }

    .player-card-total-score {
        label {
            top: 0px;
            left: -20px;
        }
    }

    .jugadores-header {
        margin-top: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid variables.$color-border;
        padding-bottom: 5px;
        width: 100%;
        font-size: 20px;
    }

    .reiniciar-alineacion-button {
        margin-bottom: 20px;
        font-size: 14px;
    }

    .alineacion-selector-text-container {
        padding-left: 0px;
    }

    .alineacion-container {
        width: 100%;
    }

    .position-info-container {
        padding-left: 0;

        h6 {
            font-size: 18px;
            border-bottom: 1px solid variables.$color-border;
            padding-bottom: 6px;
        }

        div {
            font-size: 11px;
        }
    }

    .armador-player-selected-card {
        div.armador-player-selected-card__nickname {
            width: 20% !important;
            text-overflow: ellipsis;
            overflow-y: hidden;
            white-space: nowrap;
        }

        div.armador-player-selected-card__price {
            width: 30% !important;
            font-size: 12px;
            position: relative;
            top: 3px;
        }

        div.armador-player-selected-card__psfy {
            width: 25% !important;
            font-size: 12px;
            position: relative;
            top: 3px;
        }

        div.armador-player-selected-card__media {
            width: 25% !important;
            font-size: 12px;
            position: relative;
            top: 3px;
        }
    }

    .stadium-container {
        width: 100%;
        height: 500px;

        .players-line {
            width: 100%;
        }

        .armador-image-cards__placeholder {
            width: 50px;
            height: 50px;
        }
    }

    .stadium-with-players-container {
        padding-right: 0;
        padding-left: 0;
    }

    .armador-image-cards {
        width: 50px;
        height: 50px;
    }

    .armador-player-card {
        width: 50px;
        height: 50px;
    }
}

@media screen and (max-width: 450px) {
    .top-subidas-bajadas-card-container {
        margin-top: 50px;
    }
}

@media screen and (max-width: 320px) {
    .stadium-container {
        height: 400px;
    }
}

.armador-image-cards__placeholder:hover {
    cursor: pointer;
}

.stadium-with-players-container-af {
    .li-item {
        color: rgba(0, 0, 0, 0.8);
    }
}

div .armador-container__content-container-af {
    padding-right: 0 !important;
}

.alineacion-team-image {
    position: absolute;
    top: 45px;
    width: 80px;
    left: 10px;
}

.armador-container__content-container-pr-0 {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: -10px;
    margin-top: -6px;

    @media screen and (min-width: 1000px) {
        margin-top: 12px;
    }
}

.caption-elegir-jugador {
    font-size: 12px;
    text-align: center;
    background: #fff;
    color: #000;
    margin-top: 5px;
    border-radius: 2px;
    height: 18px;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-performance-card {
    border: 1px solid variables.$color-border;
    padding: 5px;
    margin-bottom: 15px;

    .player-performance-card__header {
        .player-performance-card__header__title {
            font-size: 14px;
        }

        .lineup-selector-modal__body__row__player-info__right {
            gap: 5px;
        }
    }

    .player-performance-card__body {
        .player-performance-card__body__title {
            font-size: 12px;
        }
    }

    &__tabs {
        font-size: 12px;

        &__contain {
            padding-left: 0;
            display: flex;
            flex-direction: row;
            width: 100%;

            .react-tabs__tab {
                width: 33.33333%;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__rivals {
            .predicted-player-score {
                .fixture-score-container__color {
                    padding-top: 2px;
                    margin-left: 5px;
                }
            }

            &__line {
                margin-bottom: 5px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding-bottom: 5px;
                font-size: 13px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 30px;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }
        }

        &__rivals {
            &__score {
                display: flex;
                flex-direction: column;

                &__header {
                    background: #f4effa;
                    margin-bottom: 9px;
                    padding: 5px;
                }

                &__scores {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                }
            }
        }
    }

    &__line {
        margin-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 5px;
        font-size: 13px;
        display: flex;
        align-items: center;
        height: 30px;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

.status {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 16px !important;
    position: relative;
    bottom: 1.3px;
}

.status-messi {
    color: #1d6eb1;
}

.status-good {
    color: #71cd82;
}

.status-acceptable {
    color: #10711f;
}

.status-bad {
    color: #e7921a;
}

.status-very-bad {
    color: #be1212;
}

.status-juega-poco {
    color: #ad9942;
}

.status-no-juega {
    color: #fff;
    background: #7d7171;
    border-radius: 2px;
}

.rivals {
    margin-left: 5px;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 0px;
    margin-top: 1px;
}

.scores-days {
    overflow-x: scroll;
}

.tableContainer {
    max-width: 100%;
    overflow-x: auto;
    position: relative;

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
    }

    td,
    th {
        text-align: center;
        width: calc(50% - 125px);
        min-width: 150px;
    }

    td,
    th {
        &:first-child {
            position: sticky;
            left: 0;
            background-color: white;
            z-index: 1;
            text-align: left;
            width: 250px;
        }
    }
}

@keyframes jump {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-5px);
    }
    60% {
        transform: translateY(-2px);
    }
}

.jump-animation {
    animation: jump 2s infinite;
}
