// 🎯 General Styles for Fantasy Estadísticas Page

.player-ratings-page {
    padding: 20px;
    background-color: #f4f4f4;
}

.player-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    padding: 8px;
    margin: 5px;
    width: 90px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    &__image {
        border-radius: 50%;
        object-fit: cover;
    }

    &__score {
        margin-top: 5px;
        font-size: 14px;
        font-weight: bold;
    }

    &__name {
        margin-top: 3px;
        font-size: 12px;
        line-height: 16px;
    }
}

.tier-section {
    margin-top: 20px;

    &__title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
}

.player-ratings-grid {
    display: flex;
    flex-direction: column;
}

.player-ratings-text {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.6;

    h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    p {
        margin: 5px 0;
    }
}

// 🎯 General Styles for Estadísticas Page

.estadisticas-container {
    padding: 20px;
    background-color: #f9fafc;
}

.estadisticas-tab {
    margin-top: 20px;

    &__title {
        font-size: 22px;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    &__subtitle {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    &__description {
        font-size: 14px;
        margin-bottom: 20px;
        color: #6c757d;
    }
}

// 🛠 Tabs Style Overrides
.MuiTabs-root {
    margin-bottom: 0px;
}

.MuiTab-root {
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.estadisticas-container {
    padding: 20px;
}

.estadisticas-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.estadisticas-description {
    font-size: 14px;
    margin-bottom: 20px;
}

.estadisticas-filters {
    margin-bottom: 16px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;

    h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .estadisticas-filters__options {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        label {
            margin-right: 12px;
            font-size: 14px;
        }
    }
}

.estadisticas-module {
    margin-top: 20px;

    &__title {
        font-size: 18px;
        margin-bottom: 10px;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
    }
}

.estadisticas-card {
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 10px;
    text-align: center;
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__name {
        margin-top: 5px;
        font-size: 14px;
        width: 85px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background: #e9e4e4;
        padding: 4px 2px;
        border-radius: 2px;
    }
}

.estadisticas-text {
    margin-top: 20px;
    padding: 20px;
    background: #f9fafc;
    border: 1px solid #e1e4e8;
    border-radius: 8px;

    &__title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    &__subtitle {
        font-size: 18px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    p {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 10px;
    }

    strong {
        font-weight: bold;
        color: #2a2a2a;
    }

    &__empty {
        text-align: center;
        font-style: italic;
        color: #888;
    }

    section {
        margin-top: 15px;
    }
}
