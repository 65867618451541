@use 'variables';

.af-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    gap: 6px;

    .af-col-6 {
        width: calc(50% - 3px);
    }

    .af-col-6-left {
        border-right: 1px solid variables.$color-border;
    }
}

.mb-16 {
    margin-bottom: 16px;
}
