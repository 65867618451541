@use 'mixins';
@use 'variables';

.pl-0 {
    padding-left: 0 !important;
}

.d-none {
    display: none !important;
}

.p-10 {
    padding: 10px;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.p-0 {
    padding: 0;
}

.ml-5 {
    margin-left: 5px;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.mb-m-25 {
    @media screen and (max-width: 758px) {
        margin-bottom: 15px;
    }
}

.w-100 {
    width: 100%;
}

.d-flex-center {
    @include mixins.center-content-flex;
}

.d-flex-center-column {
    @include mixins.center-content-column-flex;
}

.analitica-box-shadow {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
}

.image-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &__image {
        margin-bottom: 3px;
    }

    &__text {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        text-align: center;

        @media screen and (min-width: 600px) {
            font-size: 14px;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;
    background: #ebebeb;
    margin: 20px 0;
}

.af-card {
    box-shadow: 0 1px 2px 1px #e1e1e1 !important;
    border-radius: 2px !important;
    margin-bottom: 12px;
    background-color: #fff;

    &__head {
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        padding: 6px 10px;
        background-color: #f9f9f9;
        line-height: 22px;
        height: 34px;
        border-bottom: 1px solid #ebebeb;
    }

    &__head-text {
        font-size: 16px;
        text-transform: uppercase;
        color: #383838;
        font-weight: 600;
    }

    &__head-link {
        font-size: 16px;
        color: variables.$color-clickable-link;
        font-weight: 400;
        cursor: pointer;
    }

    &__body {
        background: #fff;
    }
}

.text-12-16 {
    font-size: 12px;

    @media screen and (min-width: variables.$breakpoint-md) {
        font-size: 16px;
    }
}

.text-14 {
    font-size: 14px;
}

.text-14-16 {
    font-size: 14px;

    @media screen and (min-width: variables.$breakpoint-md) {
        font-size: 16px;
    }
}

.custom-table {
    table-layout: fixed;
    width: 100%;
    overflow-x: auto;

    .publift-ads-flash-incontent-1,
    .publift-ads-flash-incontent-2 {
        @media screen and (max-width: variables.$breakpoint-sm) {
            justify-content: flex-start;
        }
    }
}

.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Fondo gris sutil */
    z-index: 9999;
    pointer-events: all;
}

.spinner-overlay {
    position: absolute;
    /* Asegurarse de que cubra todo */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.football-spinner {
    z-index: 10000;
    animation: rotateFootball 1.5s linear infinite;
    pointer-events: all;
}

/* Animación de rotación */
@keyframes rotateFootball {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Opcional: agregar animación de rebote */
.football-spinner {
    animation: rotateFootball 1.5s linear infinite, bounceFootball 0.7s ease-in-out infinite alternate;
}

@keyframes bounceFootball {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-50px);
    }
}

.custom-image-container {
    aspect-ratio: 16/9;
}

.link {
    color: #0070f3;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    white-space: nowrap;
}

.magic-hover {
    &:hover {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        transform: scale(1.05);
        transition: all 0.3s;
    }
}

.link-underline {
    text-decoration: underline;
    color: #0070f3;
}

.clickable-element {
    text-decoration: underline;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

.hover-effect {
    transition: all 0.3s;

    &:hover {
        transform: scale(1.05);
    }
}

.hover-effect-transparent {
    &:hover {
        opacity: 0.9;
    }
}
