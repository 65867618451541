@use "../../shared/variables";

.mobile-navigation-container {
    background: rgb(34, 39, 47);
    height: variables.$navigation-height;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    position: sticky;
    width: 100%;
    z-index: 11;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    top: 0;

    &__menu-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 33%;
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33%;
    }

    &__partidos-icon {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 33%;
        flex-direction: column;

        .icon-partidos-drawer {
            color: white;
            font-size: 30px;
            cursor: pointer;
        }

        .icon-partidos-drawer-text {
            color: white;
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 0;
        }

        .new-sections-mobile-span {
            border: none;
            border-radius: 30px;
            padding: 1px 6px;
            background-color: #656af1;
            color: #ffffff;
            position: absolute;
            font-size: 10px;
            right: 5px;
            top: 6px;
        }
    }
}
