.custom-alert-container {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    padding: 4px 8px;
}

.leyenda-alineaciones {
    &__line {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    &__streak {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        color: #fff;

        h6 {
            font-size: 12px;
        }
    }
}

.fixed-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background: white; /* Asegúrate de que el fondo sea blanco para que se vea bien */
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.custom-table-container {
    width: 100%;
    font-family: 'Arial', sans-serif;
    font-size: 14px;
}

.custom-table-title {
    padding: 10px;
    background-color: #f5f7f9;
    border-bottom: 2px solid #ccc;
}

.table-wrapper {
    overflow-x: auto;
    max-width: 100%;
    position: relative;
}

.custom-table {
    width: 100%;
}

.custom-table th,
.custom-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    height: 80px;
    text-align: left;
    font-size: 14px;
}

.custom-table th {
    background-color: #f5f7f9;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    position: sticky;
    top: 0;
    white-space: nowrap;
}

.custom-table td {
    white-space: nowrap;
}

.fixed-column {
    position: sticky;
    left: 0;
    background-color: white;
    z-index: 2;
}

.sort-arrow {
    display: inline-block;
    width: 16px;
    text-align: right;
    margin-left: 8px;
}

.arrow-up {
    font-size: 14px;
}

.arrow-down {
    font-size: 14px;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    font-size: 14px;
}

.total-items-row {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    text-align: center;
}

.total-items-row-1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
}
.pagination-button {
    background-color: transparent;
    color: #1976d2;
    border: 1px solid #1976d2;
    padding: 6px 16px; /* Tamaño padding ajustado para que no crezca con el borde */
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box; /* Esto asegura que el padding + borde no incrementen el tamaño */
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
    font-size: 14px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

    &:first-child {
        margin-right: 8px;
    }
}

.pagination-button:hover {
    background-color: rgba(25, 118, 210, 0.08); /* Hover similar a Material UI */
}

.pagination-button:active {
    background-color: rgba(25, 118, 210, 0.16); /* Active state */
}

.pagination-button[disabled] {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.26);
    border-color: rgba(0, 0, 0, 0.26);
    cursor: not-allowed;
}

.pagination-select {
    padding: 6px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
}
