@use '../shared/variables';

.lineups-bar {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0;
    gap: 8px;
    margin-bottom: 16px;

    &__fixture {
        display: inline-block;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        text-align: center;
        border: 1px solid #e5e5e5;

        @media screen and (min-width: variables.$breakpoint-md) {
            width: 150px;
        }
    }

    &__fixture-date {
        height: 30px;
        background-color: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        padding-left: 4px;
        padding-right: 4px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        @media screen and (min-width: variables.$breakpoint-md) {
            font-size: 14px;
        }
    }

    &__fixture-teams {
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-around;
        padding-left: 4px;
        padding-right: 4px;
    }

    &__fixture-team {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &:first-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            padding-bottom: 2px;
        }
    }

    &__fixture-team-name {
        display: flex;

        gap: 4px;
    }

    &__team-name {
        font-size: 12px;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 55px;

        @media screen and (min-width: variables.$breakpoint-md) {
            font-size: 14px;
            max-width: 105px;
        }
    }
}

.fixture-round-cards-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 16px;
}

.fixture-round-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.fixture-round-card {
    box-sizing: border-box;
    background-color: white;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    overflow: hidden;
    text-align: center;

    &__header {
        background-color: #f9f9f9;
        padding: 4px;
        border-bottom: 1px solid #ddd;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: variables.$color-clickable-link;
    }

    &__teams {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;
        gap: 8px;

        &__team {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            width: calc(50% - 25px);
        }
    }

    &__scores {
        display: flex;
        gap: 4px;
    }

    &__score {
        width: 30px;
        height: 30px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
    }
}
