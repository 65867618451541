@use "../shared/variables";

.alineaciones-probables {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
        margin-bottom: 25px;
        font-size: 20px;
    }

    &__fixtures {
        max-width: 800px;
        margin: auto;
    }

    &__divider {
        margin-bottom: 25px;
    }
}

.alineaciones-probables-page {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.alineaciones-probables,
.jugadores-dudas,
.jugadores-lesionados,
.jugadores-apercibidos,
.jugadores-sancionados {
    .container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.container-mobile.container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.team-general-status-card {
    margin-bottom: 16px;

    &__label {
        display: flex;
        gap: 4px;
        font-size: 16px;
        color: #fff;
        padding: 2px 4px;
        border-radius: 4px;
        align-items: center;
        height: 50px;
        background: #f5f7f9;
        color: #000;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding-left: 8px;
    }
}

.alineaciones-probables-container {
    display: flex;
    gap: 2%;
    width: 100%;

    @media screen and (max-width: variables.$breakpoint-lg) {
        flex-direction: column;
    }

    &__alineaciones {
        width: 78%;

        @media screen and (max-width: variables.$breakpoint-lg) {
            width: 100%;
        }
    }

    &__side-events {
        width: 20%;

        @media screen and (max-width: variables.$breakpoint-lg) {
            width: 100%;
        }
    }
}

.conoce-lanzadores-penalti {
    color: #1976d2;
    font-size: '12px';
    line-height: 1.2;
    text-align: right;
    margin-bottom: 16px;
    text-decoration: underline;

    @media screen and (min-width: variables.$breakpoint-sm) {
        font-size: '14px';
    }
}

.all-team-players-container {
    min-height: 300px;
}
