@use "../shared/variables";

.jugadores-navigation {
    height: 45px;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(0, 0, 0, 0.05);

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    overflow: auto;

    @media screen and (min-width: variables.$breakpoint-sm) {
        gap: 16px;
    }

    @media screen and (max-width: variables.$breakpoint-sm) {
        justify-content: flex-start;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding-top: 2px;
        width: 55px;
        min-width: 55px;
        max-width: 55px;

        &:hover {
            color: variables.$color-clickable-link;
        }
    }

    &__item-text {
        font-size: 12px !important;
    }
}
