.preparador-jornada {
    &__btn-analyze {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;
    }

    &__btn {
        width: 180px;
    }

    @media (max-width: 768px) {
        &__btn {
            width: 100%;
        }
    }

    &__item {
        display: flex;
        width: 100%;
        margin-bottom: 8px;
        padding-bottom: 8px;
        padding-top: 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__item-0 {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__item-position {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }

    &__items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__item-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-direction: column;
    }

    &__item-info-2 {
        align-items: flex-end;
    }
}