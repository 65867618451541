.oraculo-fantasy {
    &__puntuaciones-top {
        display: flex;
        justify-content: center;
        gap: 8px;
        align-items: center;
    }

    &__puntuaciones-racha {
        display: flex;
        justify-content: center;
        gap: 4px;
    }

    &__pujas {
        display: flex;
        justify-content: center;
        gap: 8px;
        flex-direction: column;
        align-items: center;
    }

    &__puja {
        padding: 0 4px;
        background: #f9f9f9;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        width: 115px;
        display: flex;
        justify-content: space-between;
    }

    &__description {
        h2 {
            font-size: 20px;
        }

        h3 {
            font-size: 18px;
        }

        h4 {
            font-size: 16px;
        }
    }
}
