@use '../shared/variables';

.profile-general-container {
    padding-top: 25px;

    .MuiFormControl-root {
        margin-left: 0;
    }

    .profile-container {
        box-shadow: variables.$box-shadow-main;
        border-radius: 4px;
        margin-bottom: 25px;
        padding: 0 16px;

        &__information {
            display: flex;
            flex-direction: column;
            margin: 0;

            &__header {
                padding: 5px 0;

                &__title {
                    font-size: 20px;
                    padding: 0px;
                    color: variables.$color-grey-600;
                    font-weight: 400;
                    margin: 0;
                }
            }

            &__email {
                margin-bottom: 24px;
                margin-top: 24px;

                max-width: 100%;

                &__text {
                    margin-bottom: 16px;
                }
            }

            &__nickname {
                margin-bottom: 5px;
                max-width: 100%;

                &__text {
                    margin-bottom: 16px;
                }
            }
        }

        .profile-container__image-container {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 16px 0;
        }

        .profile-container__image-wrapper {
            position: relative;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 1px dashed rgba(0, 0, 0, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
        }

        .profile-container__image {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .profile-container__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: white;
            opacity: 0;
            transition: opacity 0.3s;
            cursor: pointer;
        }

        .profile-container__icon {
            font-size: 16px;
        }

        .profile-container__text {
            font-size: 16px;
            margin-top: 4px;
        }

        .profile-container__image-wrapper:hover .profile-container__overlay {
            opacity: 1; // Aparece la superposición al hacer hover
        }

        .profile-container__remove {
            font-size: 16px;
        }

        &__save {
            display: flex;
            justify-content: flex-end;
            padding-top: 5px;
            padding-bottom: 5px;

            @media (max-width: 768px) {
                .MuiButtonBase-root {
                    width: 100%;
                }
            }

            &__icon {
                margin-right: 8px;
            }
        }
    }
}
