@use "../shared/mixins";
@use "../shared/variables";

.master-player-modal {
    top: 0 !important;
    position: fixed !important;
    height: 100% !important;
    overflow-x: hidden !important;

    .MuiPaper-root {
        width: 600px !important;

        @media screen and (max-width: 620px) {
            width: 95vw !important;
        }
    }

    .master-player-header {
        @include mixins.flexbox(space-between, center, row);
        background-color: #22272f;
        color: #fff;
        padding-left: 8px;
    }

    .master-player-card .MuiDialogContent-root {
        padding: 8px !important;
        overflow-x: hidden !important;
    }

    div.MuiPaper-root.MuiDrawer-paperAnchorRight,
    div.MuiPaper-root.MuiDrawer-paperAnchorLeft {
        top: 0 !important;
    }

    .MuiBackdrop-root.MuiModal-backdrop {
        top: 0 !important;
    }

    .tab-content {
        .MuiPaper-root {
            width: 100% !important;
        }
    }
}

.master-player-modal,
.jugadores-component,
.noticia-flash__master-content {
    margin-bottom: 8px;

    .fixtures-calendar-container {
        overflow: hidden;
    }

    .card-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 85px;

        .fix-material {
            margin-bottom: 0;
        }

        .card-left {
            display: flex;
            gap: 8px;
            border-bottom: 1px solid variables.$color-border;
            padding-bottom: 8px;
            margin-bottom: 8px;

            .information {
                display: flex;
                flex-direction: column;
            }
        }

        .card-right {
            .information {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .journeys-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .journeys-title {
            width: 100%;
            text-align: center;
            background-color: #22272f;
            margin-bottom: 0s;
            color: rgba(255, 255, 255, 0.7);
            border-bottom: 1px solid rgba(255, 255, 255, 0.12);
            border-top: 1px solid rgba(255, 255, 255, 0.12);
            padding: 4px 0;
        }
    }

    .porcentaje {
        background: variables.$third-color;
        height: auto;
        width: 50px;
        transition: height 1s;
        position: absolute;
        bottom: 0;
        z-index: 1;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        color: #fff;
    }

    .tabs-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #22272f;
        padding-bottom: 12px;
        padding-top: 8px;

        .tab-to-display {
            &:hover {
                opacity: 0.7;
            }
        }

        .tab-to-display-text {
            color: #fff;
        }

        .tab-to-display-active {
            background-color: #0074d9;
        }

        .arrow-button {
            color: #fff;
            border: none;
            padding: 10px 15px;
            cursor: pointer;
            width: 40px;
            background: transparent;
        }

        .tabs {
            display: flex;
            overflow: hidden;
            width: calc(100% - 80px);

            .tab {
                background-color: #f1f1f1;
                border: 1px solid #ccc;
                margin: 0 2px;
                cursor: pointer;
                width: 20%;

                &.active {
                    background-color: #0074d9;
                    color: #fff;
                }
            }
        }
    }
}

.MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.3 !important;
}

.estadisticas-jornada {
    width: 100%;

    &__title {
        @include mixins.flexbox();
        border-top: 1px solid variables.$color-border;
        height: 55px;
    }

    &__header {
        @include mixins.flexbox(space-between, center, row);
        width: 100%;
        height: 45px;
        border-top: 1px solid variables.$color-border;
    }

    &__line {
        @include mixins.flexbox(space-between, center, row);
        width: 100%;
        height: 45px;
        border-top: 1px solid variables.$color-border;
        border-bottom: 1px solid variables.$color-border;
    }

    &__column-left {
        width: 120px;
        font-weight: 400;
        @include mixins.flexbox();
    }

    &__column-center {
        width: calc(100% - 240px);
        @include mixins.flexbox();
        text-align: center;

        h6 {
            line-height: 1.2;
        }
    }

    &__column-right {
        width: 120px;
        @include mixins.flexbox();
    }

    &__total {
        padding: 8px;
        background-color: variables.$third-color;
        color: variables.$color-white;
        display: flex;
        justify-content: space-between;
    }
}

.master-player-fantasy {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 16px 0;

    &__column {
        display: flex;
        flex-direction: column;
        width: 25%;
        justify-content: center;
        align-items: center;
    }

    &__column-top {
        font-size: 14px !important;
        color: #ccc;
    }

    &__column-bottom {
        font-size: 14px !important;
        color: #22272f;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.partidos-tab {
    &__fixture {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid variables.$color-border;
        height: 50px;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    &__bottom {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__liga {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__competition {
        margin-bottom: 0;
        font-size: 14px;
    }

    &__score {
        font-size: 14px;
    }

    &__round {
        font-size: 14px;
    }

    &__body {
        display: flex;
        align-items: center;
        padding: 4px 0;
    }

    &__center {
        width: calc(100% - 200px);
    }

    &__right {
        width: 100px;
    }

    &__column {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
    }

    &__column-first {
        width: 50px;
        border-right: 1px solid variables.$color-border;
        height: 40px;
    }

    &__column-second {
        width: 60px;
        border-right: 1px solid variables.$color-border;
        height: 40px;
    }

    &__column-row {
        display: flex;
        align-items: center;
    }

    &__events {
        display: flex;
        width: calc(100% - 180px);
        height: 40px;
        justify-content: center;
        align-items: center;
        overflow-x: auto;
    }

    &__score-fantasy {
        width: 70px;
        border-left: 1px solid variables.$color-border;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
    }

    &__header {
        display: flex;
    }

    &__header-1 {
        width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        font-size: 14px;
        height: 40px;
    }

    &__header-2 {
        width: calc(100% - 110px - 70px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        font-size: 14px;
        height: 40px;
    }

    &__header-3 {
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        font-size: 14px;
        height: 40px;
    }
}
