.master-actions-players {
    &__modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        padding: 4px 16px;
        background-color: #f9f9f9;
        border-bottom: 1px solid rgb(224, 224, 224);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &__title {
        text-transform: uppercase;
        font-weight: 600;
    }

    &__modal-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgb(250, 250, 250);
        gap: 8px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    &__modal-body-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding: 8px 8px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
        border-radius: 2px;
    }
}
