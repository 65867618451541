.chollo-player-row {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.chollo-player-card {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 16px;
    flex: 1 1 calc(33.333% - 16px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

    @media (max-width: 900px) {
        flex: 1 1 calc(50% - 16px);
    }

    @media (max-width: 600px) {
        flex: 1 1 100%;
    }

    img {
        margin-bottom: 8px;
        border-radius: 50%;
    }

    .chollo-proximo-rival-local-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin-top: 8px;

        .chollo-team-card-table-rachas {
            border-radius: 50%;
        }

        .chollo-localidad__icon {
            font-size: 18px;
        }
    }
}
