.btn-comprar-amazon {
    width: 100%;
    margin-top: 8px;
    background: linear-gradient(to bottom, #ff9900, #dbb173);
    color: #0a3b66;
    font-size: 20px;
    font-weight: bold;
    padding: 3px 20px;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &__text {
        margin-right: 8px;
    }
}

.btn-comprar-amazon:hover {
    background: linear-gradient(to bottom, #dbb173, #ff9900);
}
