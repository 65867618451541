.politicas-content {
    text-align: justify;
    padding: 0px 16px;
}

.privacy-p {
    font-size: 18px;
    line-height: 28px;
}

.privacy-header {
    font-weight: bold;
    font-size: 24px;
}

.politica-header {
    margin-top: 16px;
}

.terms-conditions {
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.restrictions-items {
    font-size: 18px;
}

.forgot-password-container {
    width: 200px;
}

div.section-divider {
    margin-bottom: 30px;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

    h4 {
        font-size: 18px;
    }

    p {
        font-size: 16px;
        line-height: 24px;
    }

    ol li {
        font-size: 16px;
        line-height: 24px;
    }
}
