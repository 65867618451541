@use "../shared/variables";

.quedada-fantasy {
    &__patrocinadores {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding: 8px 0;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__patrocinador {
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        gap: 8px;
        border: 1px solid variables.$secondary-color;
        border-radius: 4px;
        padding: 0 20px;
    }

    &__name-patrocinador {
        font-weight: bold;
        font-size: 20px;
        color: variables.$secondary-color;
    }

    &__container {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding: 8px 0;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__box {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    &__title {
        font-weight: bold;
        font-size: 20px;
        color: variables.$secondary-color;
    }
}
