$color-navigation-top: #69b59f;
$color-navigation-menu: #22272f;
$color-white: #ffffff;
$font-primary: #ffffff;
$color-border: rgba(0, 0, 0, 0.1);
$border-color: rgba(0, 0, 0, 0.1);
$color-white-opacity: rgba(255, 255, 255, 0.7);

$base-color: rgb(34, 39, 47);
$warning-color: rgb(175, 53, 32);
$error-color: rgb(175, 53, 32);
$remember-color: rgb(255, 167, 38);
$info-color: #1a892d;

$market-green-color: #1a892d;
$market-red-color: #be1212;

$position-po-color: #1d6fb1;
$position-df-color: #1a892d;
$position-mc-color: #812c7c;
$position-dc-color: #be1212;
$position-dt-color: #0f0f0f;
$position-to-color: #634e27;

$primary-color: #1976d2;
$secondary-color: #69b59f;
$secondary-color-old: #009688;
$third-color: #00a04f;
$color-header-section: #22272f;
$color-clickable-link: #0070f3;

$color-background-player-name: rgba(63, 170, 109, 0.5);

$primary-competition-color: #1d6fb1;
$secondary-competition-color: #1d6fb1;
$third-competition-color: #1d6fb1;
$last-competition-color: #1d6fb1;

$color-grey-50: hsl(265, 55%, 96%);
$color-grey-100: hsl(265, 19%, 88%);
$color-grey-200: hsl(265, 7%, 70%);
$color-grey-300: hsl(265, 6%, 66%);
$color-grey-400: hsl(265, 4%, 57%);
$color-grey-500: hsl(265, 3%, 53%);
$color-grey-600: hsl(265, 4%, 42%);
$color-grey-700: hsl(265, 4%, 31%);
$color-grey-800: hsl(276, 5%, 20%);
$color-grey-900: hsl(280, 5%, 13%);

$color-primary-50: #c8b3ce;
$color-primary-100: #a07aaa;
$color-primary-200: #884c97;
$color-primary-300: #843897;
$color-primary-400: #732392;
$color-primary-500: #5a097a;
$color-primary-600: #480264;
$color-primary-700: #3d0264;

$color-success-100: #a2f0bc;
$color-success-500: #12bd4b;

$color-error-100: #f1acc9;
$color-error-500: #a10c4a;

$size-1: 0.25rem;
$size-2: 0.5rem;
$size-3: 0.75rem;
$size-4: 1rem;
$size-5: 1.25rem;
$size-6: 1.5rem;
$size-8: 2rem;
$size-16: 4rem;
$size-20: 5rem;
$size-40: 10rem;

$color-jornada-red: #be1212;
$color-jornada-orange: #8d5f09;
$color-jornada-dark-green: #103b17;
$color-jornada-green: #1a892d;
$color-jornada-dark-blue: #1d6fb1;
$color-jornada-blue: #3230ac;
$color-jornada-gray: #ccc;

$hover-color-gray: rgba(0, 0, 0, 0.06);

$progress-bar-left: #1d6fb1;
$progress-bar-right: #1a892d;
$progress-bar-neutral: rgba(204, 204, 204, 0.3);

$menu-sub-section: #f5f7f9;

$color-risk-very-high: #ddbcbc;
$color-risk-high: #e3cda6;
$color-risk-medium: #ffffff;
$color-risk-low: rgb(118 196 118);
$color-risk-very-low: rgb(152 188 219);

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

$color-navigation-items-border: 1px solid rgba(255, 255, 255, 0);
$border-white: rgba(255, 255, 255, 0.1);

$ads-content-height: 50px;
$box-shadow-main: 0 1px 2px 1px #e1e1e1;
$navigation-height: 70px;
$social-menu-height: 30px;
$top-menu-height: 70px;

$position-move: 30px;