@use "../shared/mixins";
@use "../shared/variables";

.not-found-error-page {
    min-height: calc(100vh - 160px);

    @include mixins.center-content-flex;
}

.error-page-container {
    min-height: calc(100vh - 185px);
    position: relative;
}

.volver-info-container {
    padding: 50px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: absolute;
}

.volver-al-sitio-button {
    margin: auto;
    @include mixins.center-content-flex;

    .error-page-nav-btn {
        padding: 5px 15px;
        border-radius: 4px;
        color: variables.$color-white;
        margin-right: 15px;
        border: none;
        background: transparent;
        color: variables.$color-clickable-link;
        text-decoration: underline;
    }
}

.volver-al-sitio-text {
    margin-bottom: 25px;
    color: #000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h2 {
        font-size: 18px;
        text-align: center;
    }

    h6 {
        text-align: center;
    }
}

.overlay-background-image-error-page {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 185px);
}

.background-image-error-page {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 185px);
}