@use '../shared/variables';

.analisis-completo {
    &__detalles {
        display: flex;
        flex-wrap: wrap;
    }

    .resumen-card-container {
        position: relative;
        width: 90%;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    }

    .resumen-card {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        margin-bottom: 24px;
        width: 100px;
        text-align: center;
        position: initial;

        &__image-container {
            position: relative;
        }

        .fixture-score-container__color {
            margin-bottom: 0 !important;
        }

        .fixture-score-container {
            width: auto !important;

            .fixture-score-container__color {
                width: 18px !important;
                height: 18px !important;
            }
        }

        &__racha {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
            width: 100%;
            padding: 4px;
        }

        &__pim {
            font-size: 12px;
            display: flex;
            justify-content: center;
            gap: 4px;
        }

        &__ptos {
            font-size: 12px;
            display: flex;
            justify-content: center;
            gap: 8px;
            padding: 4px 0;
        }

        .fixture-score-container__color {
            margin-bottom: 4px;
        }

        @media screen and (max-width: variables.$breakpoint-sm) {
            width: 25%;
        }

        // Iconos de posición, equipo y estado con posición absoluta
        &__icon {
            position: absolute;
            width: 15px;
            height: 15px;

            &--position {
                top: 5px;
                right: 3px;
            }

            &--team {
                top: 0px;
                left: 3px;
            }

            &--status {
                top: 50px;
                right: 3px;
            }

            &--apercibido {
                top: 55px;
                left: 3px;
            }

            &--apercibido-card {
                background: rgb(255, 243, 106);
                border: 1px solid rgba(0, 0, 0, 0.1);
                color: #000;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                border-radius: 4px;
            }

            &--warning {
                top: 25px;
                left: 3px;
            }
        }

        &__name {
            font-size: 12px;
            background: #e9e4e4;

            border-radius: 2px;
            font-weight: normal;
            margin-top: 6px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 1px solid #f5f7f9;

            &--clickable {
                cursor: pointer;
                color: #007bff;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
