.squad-component-table {
    /* Estilo para la primera columna */
    .first-column {
        width: 20px;
        padding: 0 !important;
        margin: 0 !important;
        font-size: 12px !important;
    }

    /* Estilo para las columnas restantes */
    .other-columns {
        padding: 0 !important;
        margin: 0 !important;
        width: 50px;
        font-size: 12px !important;
    }

    table {
        td,
        th {
            font-size: 12px !important;
        }
    }
}
