@use "../../shared/variables";

.social-networks-container {
    width: 100%;
    background: rgb(34, 39, 47);
    font-size: 14px;
    display: flex;
    align-items: center;
    z-index: 15;
    position: sticky;
    top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.21);

    .new-bar {
        width: 100%;
        padding: 8px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .social-networks-text {
            color: variables.$color-white;
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 8px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .social-network-icon {
        margin-right: 10px;
        color: variables.$color-white;
        width: 18px;
        margin-right: 4px;

        &:hover {
            cursor: pointer;
            opacity: 0.8;
            transform: scale(1.1);
        }
    }

    .icon-profile {
        color: variables.$color-white;
        font-size: 32px;
    }
}