.container-tabs {
    &__header {
        margin-bottom: 20px;
    }
}

.data-table-goals,
.data-table-goal-average,
.data-table-games,
.data-table-clean-sheet,
.data-table-failed-to-score {
    table-layout: fixed;

    .first-row {
        font-size: 14px;
        white-space: nowrap;
    }

    .table-row {
        text-align: center;
        font-size: 14px;
    }

    tr td:nth-child(4),
    tr th:nth-child(4) {
        background-color: #f6f7f9;
    }

    th {
        background-color: #f6f7f9;
    }
}

.data-table-penaltis,
.data-table-cards-per-minutes {
    table-layout: fixed;

    .first-row {
        font-size: 14px;
        white-space: nowrap;
    }

    .table-row {
        text-align: center;
        font-size: 14px;
    }

    tr td:nth-child(2) {
        background-color: #f6f7f9;
    }
}

.title-tabs {
    color: #55C3B0;
    font-weight: 600;
    margin-left: 4px;
    text-transform: uppercase;
    font-size: 14px;
}