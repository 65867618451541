@use 'variables';

.player-info-card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 155px;
    margin-bottom: 16px;
    min-height: 155px;

    &__image {
        width: 100%;
        height: 99%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #2e3750;
        width: 130px;
        margin-right: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
        border-radius: 4px;
        cursor: pointer;
    }

    &__name {
        background-color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
    }

    &__text {
        font-size: 14px;
        display: flex;
        justify-self: flex-start;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 8px;
        align-items: center;
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 1px;
        align-items: flex-start;
        height: 100%;
        width: 100%;
    }

    &__info-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &__info-top {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    &__link {
        text-decoration: underline;
        color: variables.$color-clickable-link;
    }

    &__link-container {
        margin: 12px 0;
    }
}

.player-infocard-status {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__status {
        display: flex;
        gap: 4px;
        align-items: center;
    }
}

.general-transfer-status {
    display: flex;
    gap: 24px;
    align-items: center;
    border-bottom: 1px solid variables.$color-border;
    padding: 8px 0;
    width: 100%;
    justify-content: space-between;

    &__info {
        display: flex;
        gap: 4px;
        align-items: center;
        width: 45%;

        &:last-child {
            justify-content: flex-end;
        }
    }

    &__arrow {
        width: 10%;
    }

    &__separator {
        width: 10%;
        display: flex;
        justify-content: center;
    }
}

.noticias-flash {
    .general-transfer-status {
        @media screen and (min-width: variables.$breakpoint-sm) {
            justify-content: flex-start;
        }
    }
}

.player-recommendations {
    &__section {
        padding: 4px 0 8px 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__title {
        font-size: 18px;
        font-weight: 600;
        margin-top: 8px;
        display: flex;
        gap: 4px;
        align-items: center;
    }
}

.noticia-flash-single-component {
    &__header {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 8px;
    }

    &__updated {
        margin-bottom: 5px;
        font-size: 12px;
        font-style: italic;
    }

    &__updated-header {
        font-weight: 600;
    }
}

.flash-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .MuiButtonBase-root {
        width: 100%;
    }

    .fecha-div {
        display: flex;
        flex-direction: column;

        .fecha {
            display: flex;
            align-items: center;
            padding: 4px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            gap: 4px;
            justify-content: space-between;
            margin-bottom: 4px;

            background-color: #f5f7f9;
            border: 1px solid rgba(0, 0, 0, 0.1);

            div {
                display: flex;
                align-items: center;
                gap: 4px;
            }
        }
    }

    .general-transfer-status {
        border-bottom: none;
    }

    &__header {
        font-size: 18px;
        font-weight: 600;
    }

    .flash-list-card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border: 1px solid variables.$color-border;
        width: 100%;
        border-radius: 4px;
        padding: 4px;
        cursor: pointer;
        margin-bottom: 4px;

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 4px;
            padding: 4px;
            width: 100%;

            .general-transfer-status {
                display: flex;
                padding: 0;
                justify-content: flex-start;
            }
        }

        &__content-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__image-container {
            position: relative;
            width: 50px;
            height: 50px;
        }

        &:hover {
            background-color: #f0f0f0;
        }

        &__info {
            display: flex;
            align-items: center;
            gap: 4px;
            height: 54px;
            width: 100%;
        }

        &__status {
            display: flex;
            align-items: center;
            width: 100%;
        }

        &__link {
            color: #0070f3;
            margin-bottom: 0;
            font-size: 14px;
        }

        &__top {
            display: flex;
            height: 40px;
            width: 100%;
            justify-content: space-between;
        }

        &__top-section {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__top-section-right {
            align-items: flex-end;
            padding-right: 5px;
        }

        &__name {
            margin-bottom: 0;
            font-size: 16px;
            color: #0070f3;
            text-decoration: underline;
        }

        &__updated {
            font-size: 14px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }

        &__team-image {
            display: flex;
            align-items: center;
            font-size: 12px;
            gap: 4px;
        }

        &__team-name {
            font-size: 14px;
        }
    }
}

.player-social-networks {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-bottom: 8px;

    &__title {
        font-size: 18px;
        font-weight: 600;
    }

    &__icons {
        display: flex;
        gap: 2%;
        flex-wrap: wrap;
        align-items: center;
    }

    @mixin icon-style($border-color) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        gap: 8px;
        border: 1px solid $border-color;
        border-radius: 4px;
        font-weight: bold;
        cursor: pointer;
        height: 40px;
        width: 150px;
        transition: background-color 0.3s ease;
        color: $border-color;

        &:hover {
            opacity: 0.7;
        }

        @media screen and (max-width: 820px) {
            width: 49%;
            margin-bottom: 16px;
        }
    }

    &__icons-twitter {
        @include icon-style(#252727);
    }

    &__icons-tiktok {
        @include icon-style(#252727);
    }

    &__icons-telegram-news {
        @include icon-style(#1da1f2);
    }

    &__icons-telegram-community {
        @include icon-style(#42c11f);
    }

    &__icons-twitch {
        @include icon-style(#884c97);
    }

    &__icons-whatsapp {
        @include icon-style(#25d366);
    }

    @media screen and (max-width: 820px) {
        &__icons {
            align-items: flex-start;
        }
    }
}
