@use "../shared/variables";

.feed-articles-tags {
    margin-top: 8px;
    color: variables.$color-grey-300;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 0px;
}

.feed-articles-title {
    font-size: 23px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.feed-articles-container-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: variables.$color-grey-300;
    margin-bottom: 16px;
    margin-bottom: 0;

    .feed-articles-author {
        margin-right: 12px;
        text-transform: uppercase;
        font-size: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .feed-articles-last-updated {
        font-size: 12px;
        font-weight: 300;
    }
}
