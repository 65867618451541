@use '../shared/variables';

.jugador-fixtures-summary {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__fixture {
        display: flex;
        height: 40px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 1px solid variables.$color-border;
        width: 100%;
    }

    &__fixture-left {
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__fixture-center {
        width: 60%;
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__fixture-right {
        width: 20%;
        position: relative;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    &__progress-minutes {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }

    &__progress {
        position: absolute;
        left: 0;
        height: 100%;
    }

    &__progress-bar {
        border-radius: 4px;
        animation: grow 4s ease-in-out 1;
    }
}

@keyframes grow {
    0% {
        width: 1%; /* Ancho inicial del 1% al inicio de la animación */
    }
    100% {
        width: 100%; /* Ancho del 100% al final de la animación */
    }
}

.update-player-modal {
    .MuiPaper-root {
        width: 600px;

        @media screen and (max-width: variables.$breakpoint-md) {
            width: 90%;
        }
    }
}

.jugadores-page {
    .jugadores-component {
        min-height: 800px;
    }
}

.master-player-card {
    .MuiDialogContent-root {
        padding: 0;
    }
}

.lanzadores-penaltis {
    .pager {
        display: none;
    }

    h1 {
        margin-bottom: 25px;
        font-size: 24px;
        font-weight: bold;
    }

    .pagination-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        span {
            font-size: 16px;
        }
    }

    .no-data {
        padding: 20px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        text-align: center;
    }

    #lanzadores-de-penaltis {
        min-height: 500px;
        table {
            width: 100%;
            border-collapse: collapse;
            border: none;
            border: 1px solid rgba(0, 0, 0, 0.1);

            thead {
                tr {
                    height: 35px;
                    th#yw1_c0 {
                        padding-left: 8px;
                    }

                    th {
                        font-size: 16px;
                        text-transform: capitalize;

                        @media screen and (max-width: variables.$breakpoint-sm) {
                            font-size: 14px;
                        }
                    }
                }
            }

            tr {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            &:hover {
                cursor: default;
            }

            th,
            td {
                text-align: center;
            }

            th {
                background-color: #f4f4f4;
                font-weight: bold;
            }

            th {
                &:first-child {
                    text-align: left;
                }
            }

            .inline-table {
                border: none;
                td {
                    &:first-child, // Jugadores columna
                    &:nth-child(2) {
                        text-align: left;
                    }
                }

                tr {
                    border-bottom: none;
                    background-color: inherit;
                }

                tbody {
                    tr:nth-child(1) {
                        td:nth-child(1) {
                            width: 36px;
                            height: 28px;
                            border: none;
                            img {
                                width: 28;
                                height: 36px;
                                margin: 4px;
                            }
                        }
                    }
                }

                .hauptlink {
                    font-weight: 600;
                }
            }

            td.extrarow {
                text-align: left;
                background-color: #f9f9f9;
                padding-left: 8px;
                height: 40px;
                font-size: 16px;
            }
        }
    }
}

.filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .filter-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
            margin-bottom: 5px;
            font-weight: bold;
        }

        select {
            padding: 5px;
            font-size: 16px;
            border: 1px solid #ddd;
            border-radius: 4px;
        }
    }
}

.rivals-tables {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    flex-direction: column;

    &__column {
        flex-basis: 49%;
        width: 100%;
    }

    .fixtures-table {
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        margin-bottom: 20px;

        &__card-content {
            padding: 0 !important;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 27px;
            background-color: #f5f5f5;
            padding: 0px 4px 0 8px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        &__title {
            font-weight: 500;
            font-size: 14px;
        }

        &__content {
            display: flex;
            justify-content: space-between;
        }
    }

    .fixture-box {
        flex: 1;
        text-align: center;
        padding: 2px;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }

        &__icon {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
        }

        &__round {
            font-size: 10px;

            @media screen and (max-width: variables.$breakpoint-sm) {
                font-size: 12px;
            }
        }

        &:last-child {
            border-right: none;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__league {
            display: flex;
            align-items: center;

            img {
                margin-right: 4px;
            }
        }

        &__date {
            font-size: 12px;
            margin-top: 4px;
        }

        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                margin-bottom: 4px;
            }
        }

        &__result {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .team-result {
                font-size: 16px;
                font-weight: bold;
            }
        }

        &__result-score {
            padding: 0 4px;
            border-radius: 4px;
            font-size: 12px;

            @media screen and (max-width: variables.$breakpoint-sm) {
                font-size: 12px;
            }
        }
    }
}
