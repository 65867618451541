@use "../shared/variables";

.player-model {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.icon {
    margin-right: 12px;
    color: white;
    border-radius: 4px;
    font-size: 21px;
    padding: 3px;

    &__green {
        background: variables.$info-color;
    }

    &__blue {
        background: variables.$primary-color;
    }

    &__red {
        background: variables.$error-color;
    }
}

.multi-collapse {
    &__body {
        &__list {
            padding-left: 0;
            margin-bottom: 5px;
            &__item {
                list-style: none;
                border-bottom: 1px solid variables.$color-border;

                &:last-child {
                    border-bottom: none;
                }
                padding: 3px 0;
                &__text {
                    margin-bottom: 0;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    &__number {
                        margin-right: 30px;
                        font-size: 14px;
                        color: #babcc0;
                        padding-left: 16px;
                        width: 15px;
                    }
                    &__name {
                    }
                }
            }
        }
    }
}
