@use "../shared/variables";

.estadisticas-container {
    margin-top: 25px;
}

.journey-container {
    h2 {
        text-align: center;
        font-size: 16px;
        position: relative;
        top: 5px;
        margin-bottom: 12px;
    }

    display: block;
    margin-bottom: 30px;
}

.journey-container-selector {
    margin: auto;
    text-align: left;
}

.comparator-button-container {
    padding-top: 26px;
}

.comparator-container__header-container {
    margin-bottom: -24px;
    margin-top: -8px;

    @media screen and (min-width: 768px) {
        margin-bottom: -13px;
        margin-top: 12px;
    }
}

h1 {
    text-align: left;
}

.comparator-button {
    background: #0172aa;
    width: 100%;
    text-align: center;
    padding: 7px 16px;
    color: #fff;
    border-radius: 4px;
    margin: auto;

    @media screen and (max-width: 992px) and (min-width: 768px) {
        padding: 7px 9px;
    }

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.stats-comparator-players {
    margin-top: 10px;
}

.comparator-stats-container {
    margin-top: 0px;
    margin-bottom: 30px;
    padding-left: 42px;
    padding-right: 42px;

    @media screen and (max-width: 768px) {
        padding-left: 5px;
        padding-right: 5px;
    }
}

// .comparator-container {
//     width: 800px;
//     margin: auto;
// }

.player-data {
    width: fit-content;
    outline: 1px solid variables.$color-border;
    position: relative;
    background: #2e3750;
    padding: 0;
    border-radius: 4px;
    outline-style: auto;
}

.player-card {
    display: inline-flex;
    height: 160px;
}

.player-data-description-left {
    width: fit-content;
    text-align: left;

    h6 {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 8px;

        span {
            font-weight: bold;
        }
    }
}

.player-card-left {
    float: left;

    .player-data {
        margin-right: 15px;
    }
}

.player-card-right {
    float: right;
    width: 100%;
    justify-content: space-between;

    .player-data {
        margin-left: 45px;
        max-height: 161px;
    }
}

.player-presentation-card {
    background: #f2f2f2;
    border-radius: 4px;
    padding-top: 15px;
    padding-bottom: 30px;
}

.green-stats {
    color: rgb(26, 137, 45);
    font-weight: bold;
}

.black-stats {
    color: rgb(0, 0, 0);
}

.red-stats {
    color: rgb(190, 18, 18);
}

.player-data_image {
    min-width: 120px;
    margin: 10px 10px 0 10px;
}

.player-data-description-right {
    margin-right: 0px;
    text-align: left;

    h6 {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 8px;

        span {
            font-weight: bold;
        }
    }
}

.d-none {
    display: none;
}

.player-data-name {
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);
    background: rgb(253, 253, 253);
    margin-bottom: 0;
    height: 34px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-data_team {
    width: 30px !important;
    position: absolute !important;
    top: 5px !important;
    right: 5px !important;
}

.stats-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    border-bottom: 1px solid variables.$color-border;
    padding-bottom: 8px;
}

.comparator-stats-row {
    padding-left: 50px;
    padding-right: 50px;

    @media screen and (max-width: 759px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.stats-line-position-0 {
    border-top: 1px solid variables.$color-border;
}

.stats-line__left-amount {
    position: relative;
    width: 250px;
    text-align: left;
    font-size: 14px;
}

.stats-line__mid-stat {
    position: relative;
    width: 200px;
    font-size: 14px;
    text-align: center;
}

.stats-line__right-amount {
    width: 250px;
    position: relative;
    text-align: right;
    font-size: 14px;
}

.z-i-999 {
    z-index: 999;
    position: relative;
}

.stats-graphics-section {
    width: 730px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
}

.market-comparator-container {
    padding-left: 42px;
    padding-right: 42px;
    margin-bottom: 25px;
    margin-top: 35px;
}

.position-comparator-player {
    padding: 5px 7px;
    color: #fff;
    font-weight: normal !important;
}

.players-stats-selector-comparator-container {
    padding-left: 42px;
    padding-right: 42px;

    @media screen and (max-width: 759px) {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.comparator-layout {
    @media screen and (min-width: 759px) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 759px) {
    .journey-container h2 {
        text-align: left;
    }

    .market-comparator-container {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 25px;
        margin-top: 35px;
    }

    .stats-graphics-section {
        width: calc(100vw - 30px);
    }

    .player-card {
        display: inline-flex;
        width: 100%;
    }

    .player-card-right .player-data {
        margin-left: 20px;
    }

    .player-data-description-right {
        width: 100%;
    }

    .display-in-desktop {
        display: none;
    }

    .journey-container {
        padding-top: 0;
    }

    .display-in-desktop {
        display: none;
    }

    .comparator-container__stats-section {
        margin-left: 15px;
        margin-right: 15px;

        padding-top: 25px;
        padding-bottom: 25px;
        margin-top: 0;
    }

    .stats-line {
        width: calc(100vw - 30px);
    }

    .journey-container-selector {
        width: 100%;
    }

    .comparator-container {
        padding-left: 0;
        padding-right: 0;
        width: 100%;

        h1 {
            margin-top: 15px;
        }
    }

    .stats-line__left-amount {
        width: 20%;
    }

    .stats-line__mid-stat {
        width: 60%;
    }

    .stats-line__right-amount {
        width: 20%;
    }

    .player-data-right {
        margin-left: 0;
        margin-right: 15px;
        float: right;
    }

    .player-data-left {
        margin-right: 0;
        margin-left: 15px;
        float: left;
    }
}

@media screen and (min-width: 759px) {
    .player-presentation-card-left {
        border-right: 1px solid variables.$color-border;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .display-in-mobile {
        display: none;
    }

    .comparator-container__stats-section {
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 25px;
        padding-bottom: 25px;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .jornadas-container {
        .basic-multi-select {
            width: calc(715px);
            margin: auto;
        }
    }
}
