@use "../shared/variables";

.estadisticas-page-container {
    padding-top: 15px;

    .select-player-list {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        @media screen and (max-width: 600px) {
            margin-bottom: 50px;
        }

        @media screen and (min-width: 600px) {
            canvas {
                position: relative;
                bottom: 50px;
            }
        }
    }

    .select-player-header {
        font-size: 16px;
        font-weight: normal;
    }
}

.player-data-container {
    display: flex;
    border: 1px solid variables.$color-border;
    padding: 15px;
    width: 100%;

    .player-data {
        margin-right: 15px;
    }

    margin-bottom: 25px;
}

.ranking-stats-container {
    border-radius: 4px;
    padding: 15px;

    h4 {
        opacity: 0.7;
    }

    .ranking-stats__estadistica {
        margin-bottom: 7px;

        &::before {
            content: '• ';
        }
    }

    .ranking-stats__estadistica-lugar {
        font-weight: normal;
        font-style: italic;
        font-size: 12px;
    }
}

.ranking-stats-super-container {
    margin-bottom: 35px;
}

.d-inline-flex {
    display: inline-flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(1, 1, 1, 0.1);
    margin-bottom: 10px;
}

.tooltip-info {
    font-size: 12px;
    background: #1d6fb1;
    color: #fff;
    border-radius: 50%;
    padding: 5px 9px 7px 9px;
}

.ranking-stats-container {
    padding: 2px 2px 10px 2px;

    table {
        width: 100%;

        thead {
            td {
                font-weight: bold;
            }
        }

        tr {
            height: 35px;
            font-size: 14px;

            td:first-child {
                padding-left: 10px;
            }
        }

        tbody {
            tr:nth-child(odd) {
                background: #f7f7f7;
            }
        }
    }
}

.player-matches-container {
    padding: 0 15px;
    border: 1px solid variables.$color-border;
    border-radius: 4px;

    .game-row {
        height: 80px;
        border-bottom: 1px solid variables.$color-border;
    }
}

.player-matches-container__header {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;

    h4 {
        opacity: 0.7;
    }

    div {
        width: fit-content;
    }
}

@media screen and (max-width: 600px) {
    // .game-score {
    //     margin-bottom: 12px !important;
    // }

    .player-matches-container__header {
        h4 {
            font-size: 16px;
            text-align: center;
        }
    }

    .player-matches-container {
        padding: 0 20px;

        .estadisticas-link {
            font-size: 11px;
        }

        .game-mid-stats .game-time {
            font-size: 14px;
        }
    }
}

.player-data-container {
    display: inline-flex;

    .player-data-info {
        width: fit-content;
        margin-left: 15px;
        text-align: left;

        .player-data-info-line {
            font-weight: normal;

            span {
                font-weight: bold;
            }
        }
    }
}

.player-position-DF {
    background-color: #1a892d;
    border-radius: 4px;
    color: #fff;
    padding: 4px 6px;
    font-weight: normal !important;
}

.player-position-DL {
    background-color: #be1212;
    border-radius: 4px;
    color: #fff;
    padding: 4px 6px;
    font-weight: normal !important;
}

.player-position-PO {
    background-color: #1d6fb1;
    border-radius: 4px;
    color: #fff;
    padding: 4px 6px;
    font-weight: normal !important;
}

.player-position-MC {
    background-color: #812c7c;
    border-radius: 4px;
    color: #fff;
    padding: 4px 6px;
    font-weight: normal !important;
}

.lesionado-info-container {
    border: 1px solid variables.$color-border;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 35px;
    display: inline-flex;
    width: 100%;

    .lesionado-span {
        color: rgb(190, 18, 18);
        font-weight: bold;
        margin-right: 5px;
    }

    .duda-span {
        color: rgb(248, 176, 52);
        font-weight: bold;
        margin-right: 5px;
    }

    .injury-icon {
        margin-right: 10px;
    }

    .doubt-icon {
        margin-right: 10px;
    }

    .red-card-icon {
        margin-right: 10px;
    }

    h6 {
        position: relative;
        top: 3px;
    }
}

.apercibidos-info-container {
    border: 1px solid variables.$color-border;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 35px;
    display: inline-flex;
    width: 100%;

    .apercibido-span {
        color: rgb(248, 176, 52);
        font-weight: bold;
    }

    .yellow-card-icon {
        margin-right: 10px;
    }

    h6 {
        position: relative;
        top: 3px;
    }
}

.apercibidos-info-container-estadisticas {
    .yellow-card-icon {
        margin: initial;
        margin-right: 10px;
    }
}

@media screen and (min-width: 759px) {
    .donut-custom-container .chartjs-render-monitor {
        display: block;
        height: 200px !important;
        width: 73% !important;
        float: right;

        &:hover {
            font-size: 18px !important;
        }
    }

    .donut-custom-container {
        height: 350px;
    }

    .distribucion-de-puntos-container {
        padding-top: 25px;
    }

    .first-row-stats {
        height: 390px;
    }
}

.historic-market-container {
    margin-top: 35px;

    h4 {
        font-size: 16px;
        margin-bottom: 35px;
    }
}

@media screen and (max-width: 758px) {
    .apercibidos-info-container {
        .yellow-card-icon {
            margin-right: 10px;
            width: 45px;
        }
    }

    .donut-custom-container {
        height: 215px;
    }

    .info-row {
        padding-right: 15px;
        padding-left: 15px;
    }

    .historic-market-container {
        overflow-x: auto;
        width: 100%;
    }
}

.jugador-journey-container-selector {
    margin-left: 0;

    h2 {
        text-align: left;
        margin-bottom: 15px;
    }
}

.jugador-container {
    min-height: calc(100vh - 185px);

    .player-data-container {
        width: 100%;
        border: 1px solid variables.$color-border;
        border-radius: 4px;
        padding: 15px;
    }

    .player-data {
        max-height: 160px;
    }
}

.estadisticas-selector-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
    }

    .estadisticas-selector-header {
        width: fit-content;

        @media screen and (max-width: 600px) {
            margin-top: 10px;
        }
    }

    .estadisticas-selector {
        max-width: 400px;
    }
}

.jugador-estadisticas-container {
    margin-bottom: 50px;
}

.estadisticas-selector-header {
    h2 {
        font-size: 16px;
        position: relative;
        top: 5px;
        white-space: nowrap;
    }
}

.estadisticas-row {
    margin-top: 35px;
}

.select-a-player-container {
    height: 246px;
    text-align: left;
    padding-top: 12%;
    margin-top: 25px;
    border-radius: 4px;

    span {
        border: 1px solid variables.$color-border;
        padding: 15px;
        border-radius: 4px;
        font-size: 16px;
        white-space: nowrap;
    }
}

.jugador-estadisticas-container-for-selector {
    padding-right: 28px;
}

.css-2613qy-menu {
    text-align: left;
}

div.selecciona-los-mercados {
    float: right;
}

.mercado-container-options-select.selecciona-los-mercados {
    width: 200px;
    position: relative;
    right: 23px;
}

img.local-team-box {
    width: 27px;
    height: 25px;
    min-height: 30px;
    position: relative;
    top: 17px;
}

.game-row {
    // border-bottom: 1px solid $color-border;
    padding-top: 5px;
    padding-bottom: 5px;

    .last-row {
        border-bottom: none;
    }
}

.add-border-left {
    border-left: 1px solid variables.$color-border;
}

.add-border-right {
    border-right: 1px solid variables.$color-border;
}

.game-stats-part {
    padding: 0;
    text-align: center;
}

.game-row-0 {
    border-top: 1px solid variables.$color-border;
    padding-top: 5px;
    padding-bottom: 10px;
}

.game-mid-stats {
    .game-time {
        margin-bottom: 5px;
        font-size: 14px;
        opacity: 0.7;
        white-space: nowrap;
    }

    .game-score {
        color: #1d6fb1;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;
    }
}

.estadisticas-link {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    margin-bottom: 0;
    white-space: nowrap;
}

.estadisticas-link-fixtures {
    font-size: 12px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
}

.game-mid-stats {
    .game-time {
        font-size: 13px;
        position: relative;
        right: 12px;
    }
}

@media screen and (max-width: 600px) {
    .game-mid-stats {
        .game-time {
            font-size: 12px;
            position: relative;
            right: 6px;
        }
    }
}

.jornadas-container.player-matches-container {
    height: 440px;
    overflow-y: auto;
}

.jornadas-home-container {
    .col-6-left {
        border-right: 1px solid variables.$color-border;
    }
}

.jornadas-border-box {
    border-bottom: 1px solid variables.$color-border;
    padding-top: 5px;
    padding-bottom: 5px;
}