@use '../shared/mixins';
@use '../shared/variables';

.equipo-page {
    &__cabecera {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #f9f9f9;
        height: 170px;
        padding: 8px 0 4px 0;
        margin-bottom: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__cabecera-top {
        color: #000;
    }

    &__cabecera-bottom {
        @include mixins.center-content-flex;
        color: #000;
        gap: 16px;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        padding: 0 8px;

        @media screen and (max-width: variables.$breakpoint-sm) {
            justify-content: flex-start;
        }
    }

    &__cabecera-bottom-item-not-selected {
        border-bottom: 4px solid transparent;
        box-sizing: border-box;
        opacity: 0.7;
    }

    &__cabecera-bottom-item-selected {
        border-bottom: 4px solid #ccc;
        box-sizing: border-box;
    }

    &__content {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        @media screen and (max-width: 900px) {
            display: block;
        }
    }

    &__content-left {
        width: 45%;
        padding: 0 8px 0 0;
    }

    &__content-right {
        width: 55%;
        padding: 0 0 0 8px;
    }

    @media screen and (max-width: 1400px) {
        &__content-left {
            width: 50%;
        }

        &__content-right {
            width: 50%;
        }
    }

    @media screen and (max-width: 992px) {
        &__content-left {
            width: 60%;
        }

        &__content-right {
            width: 40%;
        }
    }
}

.last-fixtures {
    &__body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0;
    }

    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__box-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.vote-fixture-results {
    .MuiTypography-root {
        width: 100% !important;
    }
}

.equipo-lineup {
    &__field {
        position: relative;
        border-radius: 2px;
        height: 700px;
        background: url('https://assets.analiticafantasy.com/estadio/estadio.png?width=800&height=800');
        width: 100%;
        background-size: 100% 100%;
        margin-bottom: 25px;
        padding: 20px 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__once-tipo {
        background: url('https://assets.analiticafantasy.com/estadio/estadio-once.png?width=800&height=800');
        position: relative;
        border-radius: 2px;
        height: 700px;
        width: 100%;
        background-size: 100% 100%;
        margin-bottom: 25px;
        padding: 20px 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    &__team-line {
        display: flex;
        justify-content: center;
    }

    &__calendario-admin {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }

    &__calendario-admin-header {
        padding: 8px 16px;
        background: #f5f7f9;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        height: 40px;
        display: flex;
        align-items: center;
    }

    &__calendario-admin-header-text {
        margin-bottom: 0;
    }

    &__calendario-admin-colored {
        padding: 8px 16px;
    }
}

.once-tipo-link {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    padding: 8px;
    color: variables.$color-clickable-link;
    text-decoration: underline;
    font-style: italic;
}

.resumen-card-teams {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100px;
    text-align: center;
    position: relative;

    &__image-container {
        position: relative;
    }

    &__icon {
        position: absolute;
        width: 15px;
        height: 15px;
    }

    &__icon--position {
        top: 5px;
        right: -5px;
    }

    &__icon--team {
        top: 0;
        left: -10px;
    }

    &__icon--status {
        top: 50px;
        right: -5px;
    }
}

.player-name-box {
    font-size: 12px;
    background: #e9e4e4;
    text-align: center;
    border-radius: 2px;
    font-weight: normal;
    margin: 6px 0 0 0;
    width: 100%;
    max-width: 100px;
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #f5f7f9;
}
