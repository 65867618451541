@use "../../shared/variables";

nav.responsive-toolbar {
    background: variables.$base-color;
    height: 100px;
    width: 100%;
    border-bottom: 1px solid variables.$color-border;
    z-index: 9999;
    position: sticky;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    top: 0;
    padding-left: 7px;
    padding-right: 15px;

    i.down-arrow-dropdown-icon {
        position: relative;
        top: 2px !important;
    }

    ul.super-ul {
        display: flex;
        margin-block-start: 0;
        margin-block-end: 0;
        padding-inline-start: 0;
        height: 100%;
        align-items: center;
        border-right: 1px solid variables.$color-border;
        z-index: 9999;
        background: variables.$base-color;

        figure {
            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 10px;
            margin-inline-end: 0;
            cursor: pointer;
        }

        li {
            list-style-type: none;
            padding: 10px 20px;
            position: relative;
            text-align: center;

            .active {
                span {
                    font-weight: bold;
                }
            }

            div.simulating-a {
                color: #fff;
                text-decoration: none;
                display: inline-grid;
                align-items: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        a {
            color: white;
            text-decoration: none;
            display: inline-grid;
            align-items: center;
        }

        i {
            margin-right: 0px;
            font-size: 20px;
            color: #fff;
            height: 30px;
            position: relative;
            top: 6px;
        }
    }
}

.navigation-container {
    display: inline-flex;
    width: fit-content;
    margin: auto;
    z-index: 9999999999999999999;
}

.nav-item-jugadores {
    padding-top: 0;
    padding-bottom: 0;
}

.full-navigation-bar {
    justify-content: space-between;
    display: inline-flex;
}

.li-item-icon:hover {
    opacity: 0.9;
}

.image-logo-container {
    width: fit-content;
    display: inline-grid;
    padding-top: 0px;
    position: relative;
    top: 10px;
}

@media screen and (min-width: 961px) {
    .nav-item-sub-list-no-submenu {
        margin-top: 5px;
    }

    .hide-in-desktop {
        display: none;
    }

    .display-only-in-mobile {
        display: none;
    }

    .image-logo {
        &:hover {
            cursor: pointer;
        }
    }

    .asesorias-fantasy-logo-container {
        //display: inline-grid;

        img {
            opacity: 0.7;
        }

        span {
            color: variables.$color-white-opacity;
        }
    }
}

@media screen and (max-width: 960px) {
    nav.responsive-toolbar {
        & ~ * {
            padding-top: 0px;
        }
    }

    .border-top-solid-white {
        border-top: 1px solid variables.$color-white-opacity;
    }

    .border-bottom-none {
        border-bottom: none !important;
    }

    .navigation-container li {
        border-bottom: 1px solid variables.$color-white-opacity;
        padding-left: 10px;

        i {
            margin-left: 10px;
        }
    }

    li.nav-item-sub-list-only-sublist {
        height: auto !important;
    }

    li.nav-item-sub-list {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        ul {
            width: 100%;
            padding-left: 0px;
            padding-top: 0;
            margin-top: 0;
        }

        ul.nav-sub-list-ul {
            margin-left: 0 !important;

            li {
                padding-top: 5px !important;
                padding-bottom: 7px !important;
                padding-left: 10px !important;
                opacity: 1;
                height: 45px;
                font-size: 16px;
            }
        }
    }

    .full-navigation-bar {
        padding-left: 0;
        padding-right: 0;
    }

    .navigation-container {
        margin-left: 0px;
        width: 100%;
    }

    .nav-item-jugadores {
        padding-bottom: 0;
    }

    .asesorias-fantasy-logo-container {
        &:hover {
            cursor: pointer;
            opacity: 0.9;
        }

        span {
            color: variables.$color-white-opacity;
            position: relative;
            right: 7px;

            &:hover {
                cursor: pointer;
                opacity: 0.9;
            }
        }
    }

    .bar-menu-responsive {
        left: 20px;
        top: 30px;
        font-size: 26px;
        display: flex;
        justify-content: flex-start;
        width: 33%;

        i {
            color: variables.$color-white-opacity;
        }
    }

    .image-logo-container {
        top: 10px;
        opacity: 0.8;
        right: 10px;
        width: 33%;
        display: flex;
        justify-content: center;
        align-items: center;

        .image-logo {
            width: 90px;
            opacity: 1;
            position: relative;
            bottom: 5px;
        }
    }

    .gray-background {
        background: variables.$color-white-opacity;
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 9998;
        top: 140px;
    }

    .navigation-container {
        display: block;
    }

    nav.responsive-toolbar-open {
        position: fixed;
        border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
        background: variables.$base-color;
        top: 0px;
    }

    nav.responsive-toolbar {
        .responsive-toolbar__partidos {
            color: #fff;
            width: 33%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .responsive-toolbar__partidos__subcontainer {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .icon-matches {
                    font-size: 24px;
                    margin-top: 20px;
                    margin: auto;
                    opacity: 0.7;
                }

                &__text {
                    margin-bottom: 0;
                    opacity: 0.7;
                }
            }
        }
    }
}

ul.super-ul {
    z-index: 9999999;
    height: 100%;
    width: 100vw;
    left: -100vw;
    position: fixed;
    top: 100px;
    flex-direction: column;
    transition: 300ms ease all;
    overflow-y: auto;

    &.active {
        left: 0px;
    }

    figure {
        margin-inline-start: 2px;
        left: 9px;
        top: 10px;
        position: absolute;
        left: 100px;
    }

    li {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        // text-align: left;
        position: relative;
    }

    div.simulating-a {
        color: #fff;
        text-decoration: none;
        display: inline-flex !important;
        align-items: center;

        i {
            width: fit-content;
            margin-right: 10px;
            position: relative;
            top: 4px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    a {
        margin-left: 0;
        margin-right: 16px;
        display: inline-flex;

        i {
            margin-right: 10px;
            width: 20px;
        }
    }
}

.navigation-menu-item {
    li.nav-item-sub-list-no-submenu:hover {
        opacity: 0.7;
    }
}

.nav-sub-list-menu__li-dropdown-menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

li.nav-item-sub-list {
    height: 45px;
    padding-top: 5px !important;
}

.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    color: #222222;
    justify-self: flex-start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
}

.fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: flex-end;
    margin-right: 2rem;
}

.nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
}

.nav-links:hover {
    background-color: rgb(98, 124, 134);
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #222222;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.dropdown-menu {
    width: 220px;
    position: absolute;
    top: 35px;
    left: -15px;
    list-style: none;
    text-align: start;
    display: block;
    padding-top: 0;
    padding-bottom: 0;

    li {
        border-radius: 4px;
        margin-left: 0;
        padding-left: 0;

        a {
            margin-left: 0;
            padding-left: 0;
        }
    }

    .active {
        span {
            border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
        }
    }
}

.dropdown-menu li {
    text-align: left;
    background: #fff;
    padding: 0;
    color: #222222;
    cursor: pointer;

    span.li-item {
        color: rgba(0, 0, 0, 0.7);
    }

    .li-item-drodown-icons {
        position: relative;
        right: 5px;
        width: 29px;
        bottom: 3px;
        opacity: 0.7;
    }

    .fas.fa-user {
        right: 0 !important;
    }
}

.dropdown-menu.clicked {
    display: none;
}

.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #222222;
    padding: 6px;
    display: inline-flex !important;

    .li-item-drodown-icons {
        color: #222222 !important;
    }
}

.li-dropdown-menu {
    &:hover {
        opacity: 0.7;
        background: variables.$color-border;
    }

    padding-right: 5px;
    padding-left: 5px;

    .li-item-drodown-icons {
        margin-right: 5px;
    }

    text-align: left !important;

    span {
        color: #222222;
    }
}

@media screen and (max-width: 960px) {
    .dropdown-link {
        a {
            &::before {
                content: '+';
                margin-right: 3px;
            }
        }
    }

    .NavbarItems {
        position: relative;
    }

    .fa-caret-down {
        display: none;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #1888ff;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #222222;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #1888ff;
        text-decoration: none;
        color: #222222;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: #222222;
        color: #1888ff;
        transition: 250ms;
    }
}

.display-only-in-desktop {
    @media screen and (max-width: 962px) {
        display: none;
    }

    @media screen and (min-width: 962px) {
        display: block;
    }
}

.display-mobile-sm {
    @media screen and (min-width: variables.$breakpoint-sm) {
        display: none;
    }
}

.display-desktop-sm {
    @media screen and (max-width: variables.$breakpoint-sm) {
        display: none;
    }
}
