.puja-fantasy {
    .rdt_TableRow {
        height: 90px;
    }

    .puja-ideal-number {
        &::before {
            content: '< ';
        }
    }
}

.wsnw {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.puja-ideal-table {
    th,
    td {
        text-align: center;
    }

    th:nth-child(2),
    td:nth-child(2) {
        text-align: left;
    }
}
