@use "../shared/mixins";
@use "../shared/variables";

.login-page {
    min-height: calc(100vh - 235px);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__container {
        @include mixins.center-content-flex;

        &__form {
            border: 1px solid variables.$color-border;
            border-radius: 4px;
            width: 350px;
            padding: 0 15px;
            position: relative;

            &__header {
                @include mixins.center-content-flex;
                border-bottom: 1px solid variables.$color-border;
                padding: 20px 0;

                &__text {
                    font-size: 24px;
                    font-weight: 400;
                    color: rgb(128, 128, 128);
                    margin-bottom: 0;
                }
            }

            &__content {
                width: 100%;

                &__body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 15px;
                    margin-bottom: 25px;
                }

                &__actions {
                    @include mixins.center-content-flex;
                    margin-bottom: 24px;

                    &__login {
                        @include mixins.center-content-flex;
                        width: 100%;

                        &:hover {
                            cursor: pointer;
                            opacity: 0.9;
                        }
                    }
                }

                &__forgot-password {
                    @include mixins.center-content-flex;
                    margin-bottom: 20px;

                    &__text {
                        font-size: 14px;

                        &__link {
                            color: #0d6efd;
                            text-decoration: underline;
                        }
                    }
                }
            }

            &__register {
                @include mixins.center-content-flex;
                margin-bottom: 20px;
                margin-top: 20px;

                &__text {
                    font-size: 16px;

                    &__link {
                        color: #0d6efd;
                        text-decoration: underline;
                    }
                }
            }

            &__law-agreements {
                font-size: 11px;
                font-style: italic;
                margin-top: 15px;
                margin-bottom: 15px;
                text-align: center;
            }

            &__external-login {
                @include mixins.center-content-flex;
                gap: 15px;
                margin-top: 12px;
                position: absolute;
                bottom: -19px;
                left: 0;
                width: 100%;

                &__action {
                    border-radius: 50%;
                    padding: 10px;
                    border: none;
                    color: #fff;
                    width: 45px;
                    height: 40px;
                    font-size: 44px !important;

                    &:hover {
                        cursor: pointer;
                        opacity: 0.9;
                    }
                }

                &__facebook {
                    background: #3b5998;

                    &:hover {
                        background: #3b5998;
                    }
                }

                &__google {
                    background: #db4437;

                    &:hover {
                        background: #db4437;
                    }
                }

                &__twitter {
                    background: #1da1f2;

                    &:hover {
                        background: #1da1f2;
                    }
                }
            }
        }
    }
}

.social-network-divider {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    &__line {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        height: 2px;
        width: calc((100% - 20px) / 2);
    }

    &__separator {
        font-size: 12px;
        font-style: italic;
        width: 20px;
        text-align: center;
    }
}

#password-label {
    top: 5px;
    left: 19px;
}

#repassword-label {
    top: 5px;
    left: 19px;
}
