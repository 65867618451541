@use "../shared/variables";

.home-container {

    .fantasy-calendar-title {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 20px;
    }

    min-height: calc(100vh - 140px);

    @media screen and (max-width: 578px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.home-card {
    padding: 15px 15px 15px 15px;

    h2 {
        font-size: 24px;
        text-align: left;
        margin-bottom: 15px;
        color: #222222;
        opacity: 0.7;
    }

    img {
        width: 100%;
        height: 435px;
        min-height: 435px;
    }
}

.jornadas-selector-container div {
    width: fit-content;
    background: #0172aa;
    color: #fff;
    padding: 7px 11px 7px 11px;
    border-radius: 4px;
    font-size: 12px;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }

    &.active {
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        pointer-events: none;
    }
}

.modal-body__player-card-container-0 {
    border-top: 1px solid variables.$color-border;
    padding-top: 15px !important;
}

.top-subidas-bajadas-card-mvp {
    // margin-top: 90px;
    border: 1px solid variables.$color-border;
    padding: 15px 15px 15px 15px;
    border-radius: 4px;
}

.jornadas-selector-container-mvp {
    border-bottom: 1px solid variables.$color-border;

    h2 {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.7);
    }
}

div.modal-body__player-card-container.modal-body__player-card-container-mvp {
    padding-left: 0;

    .team-position-container {
        width: 11%;
    }
}

.rocket-icon-puntos {
    font-size: 11px;
    background: #b32d15;
    padding: 8px 8px 7px 7px;
    border-radius: 50%;
    color: #fff;
}

.jornadas-card {
    @media screen and (min-width: 900px) {}

    h2 {
        font-size: 24px;
        text-align: left;
        margin-bottom: 15px;
        color: #222222;
        opacity: 0.7;
    }
}

.jornadas-card-only-jornadas {
    padding-bottom: 0;
}

.top-subidas-bajadas-card {
    border: 1px solid variables.$color-border;
    padding: 15px 15px 15px 15px;
    border-radius: 4px;

    h2 {
        font-size: 24px;
        text-align: left;
        margin-bottom: 15px;
        color: #222222;
        opacity: 0.7;
    }
}

.ver-subidas-bajadas-button {
    // text-align: center;
    padding: 9px 16px;
    width: fit-content;
    margin: auto;
    margin-right: 0;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;

    &:hover {
        color: #fff;
        text-decoration: none;
        opacity: 0.9;
        cursor: pointer;
    }
}

.jornadas-container.modal-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 5px;

    .players-list-container {
        height: auto;
    }

    .player-stats-container {
        text-align: left;
    }

    .player-stats-info {
        text-align: left;

        .player-stats-info-difference-price {
            font-size: 14px;
            font-weight: 400;

            .subidas-mercado-verdes {
                font-weight: bold;
                color: #1a892d;

                &:after {
                    margin-left: 5px;
                    content: '\25bc';
                    transform: rotate(180deg);
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                }
            }

            .bajadas-mercado-rojas {
                color: #be1212;
                font-weight: bold;

                &:after {
                    margin-left: 5px;
                    content: '\25bc';
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;
                }
            }

            .arrow-up-price {
                background: #1a892d;
                color: #fff;
                padding: 5px;
                border-radius: 50%;
                font-size: 10.5px;
                margin-right: 3px;
            }

            .arrow-down-price {
                background: #be1212;
                color: #fff;
                padding: 5px;
                border-radius: 50%;
                font-size: 10.5px;
                margin-right: 3px;
            }

            .color-red {
                color: #be1212;
            }
        }

        .player-stats-info-status-home {
            font-size: 14px;
            font-weight: 400;

            span {
                font-weight: bold;
            }
        }
    }

    .player-stats-score {
        width: 43%;

        .player-stats-info__nickname {
            h6 {
                font-weight: normal;

                span {
                    font-weight: bold;
                }
            }
        }

        .player-stats-info__price {
            span {
                font-weight: bold;
            }
        }
    }
}

.home-container__header-container {
    margin-bottom: 15px;
    margin-top: 0px;

    .recent-news {
        margin-top: 32px;
    }
}

.jornadas-selector-container {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 15px;
    gap: 6px;

    .jornadas-selector-container-button {
        @media (min-width: 280px) and (max-width: 321px) {
            font-size: 10px;
        }

        @media (min-width: 768px) and (max-width: 1200px) {
            height: 40px;
            font-size: 10px;
        }
    }
}

.icon-jornada-selector {
    font-size: 24px;
    position: relative;
    top: 5px;
    color: rgba(0, 0, 0, 0.7);

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
}

.player-stats-info-difference-price {
    white-space: nowrap;

    h6 {
        margin-bottom: 0;
    }
}

.player-stats-info-status-home {
    white-space: nowrap;
}

.ver-detalles-link {
    font-size: 18px;
    color: #0172aa;
}

.once-rentable-jornada {
    position: relative;

    h2 {
        position: absolute;
        top: 10px;
        left: 25px;
        color: #fff;
    }

    padding: 0;
}

.home-row {
    margin-bottom: 35px;
}

.once-ideal-container__once-ideal-header {
    position: absolute;
    font-size: 20px;
    top: -34px;
    left: -10px;
    color: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 4px;
}

.article-box {
    border-bottom: 1px solid variables.$color-border;
}

.article-box__text-container {
    a {
        color: rgba(0, 0, 0, 0.9);
    }
}

#once-ideal-container {
    .armador-player-card__caption {
        width: 50px;
        font-size: 11px;
        min-height: 19px;
        height: 19px;
    }

    .armador-image-cards {
        width: 50px;
        height: 50px;
    }

    .stadium-container {
        display: block;
    }

    .armador-player-card {
        width: 50px;
        height: 50px;
    }

    .players-line {
        width: 100%;
    }

    .stadium-container {
        padding-top: 23px;
    }
}

.article-box__article-image {
    width: 100%;
    border-radius: 4px;

    &:hover {
        cursor: pointer;
    }
}

.article-box__text-container__header {
    font-size: 24px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 15px;

    &:hover {
        color: rgb(59, 130, 246);
        cursor: pointer;
    }
}

.article-box__text-container__subheader {
    font-size: 16px;
    text-align: left;
    color: #6b6b6b;
    line-height: 1.5;
    font-weight: normal;
    margin-bottom: 20px;
}

.article-container {
    margin-bottom: 25px;
    transition: transform ease 300ms;

    &:hover {
        transform: translate(0, -10px);
    }
}

.ver-mas-articulos-button {
    background: #0172aa;
    color: #fff;
    width: fit-content;
    margin: auto;
    padding: 7px 20px;
    border-radius: 4px;
    margin-bottom: 25px;
    text-align: center;

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
}

.ver-subidas-bajadas-button {
    width: 100%;
    text-align: center;
}

.ver-mas-articulos-button {
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 758px) {
    .jornadas-container.modal-body {
        margin-bottom: 0;
    }

    .ver-mas-articulos-button {
        width: 100%;
    }

    #once-ideal-container {
        margin-bottom: 15px;
    }

    div.modal-body__player-card-container.modal-body__player-card-container-mvp {
        img {
            position: relative;
            right: 5px;
        }
    }

    .top-subidas-bajadas-card-mvp {
        margin-top: 60px;
    }

    .home-row {
        margin-bottom: 0;
    }

    .top-subidas-bajadas-card-container {
        margin-bottom: 50px;
    }

    // .once-ideal-container__once-ideal-header{
    //     top: 9px;
    // }

    .my-twitter-user {
        top: 11px;
        right: 10px;
    }

    .jornadas-container.modal-body .player-stats-info .player-stats-info-status-home {
        font-size: 14px;
    }

    .modal-body .player-stats-info .player-stats-info__nickname h3 {
        margin-bottom: 4px;
    }

    .top-subidas-bajadas-card {
        padding-right: 5px;
        padding-left: 5px;

        .modal-body {
            .modal-body__player-card-container {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.players-list-container-top-subidas-bajadas {
    overflow-x: hidden;
}

.modal-body__player-card-container-top-subidas-bajadas {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.jornadas-container-top-subidas-bajadas {
    margin-bottom: 35px;
}

.top-subidas-bajadas-card {
    margin-bottom: 50px;
}

.li-item {
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }
}

@media (min-width: 600px) and (max-width: 895px) {
    .top-subidas-bajadas-card-container {
        margin-top: 35px;
    }
}

@media only screen and (max-width: 600px) {
    .team-position-container__image {
        position: relative;
        right: 5px;
    }
}

.home-container__header-container {
    margin-bottom: 0px;

    @media screen and (min-width: 768px) {
        margin-bottom: 3px;
    }

    .classification-la-liga-text {
        margin-top: 28px;

        @media screen and (min-width: 768px) {
            margin-top: 55px;
        }
    }
}

.player-stats-container-mvp-card {
    width: 30% !important;
}

.top-subidas-bajadas-card-mvp {
    margin-top: 0;
    margin-bottom: 35px;
}

.text-help-content {
    h2 {
        font-size: 18px;
        margin-top: 8px;
    }
}