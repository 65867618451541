@use "../shared/variables";

.team-page-section {
    padding-bottom: 24px;

    &__title {
        font-size: 20px;
        line-height: 1.6;
        margin-bottom: 16px;
        margin-top: 16px;
    }
}

.team-logo-container {
    height: 50px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 8px;

    @media screen and (min-width: variables.$breakpoint-sm) {
        height: 65px;
    }

    .container {
        @media screen and (max-width: 880px) {
            margin-left: 0;
        }
    }

    &__subcontainer {
        display: flex;
        gap: 12px;
        justify-content: flex-start;
        margin-bottom: 0;

        span {
            width: 35px !important;
        }

        @media screen and (min-width: 880px) {
            justify-content: center;
        }

        @media screen and (max-width: 578px) {
            padding-left: 0;
        }
    }

    &__image {
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    .team-logo {
        cursor: pointer;

        &:hover {
            opacity: 0.8;
            transform: scale(1.1);
        }
    }
}

.general-container {
    @media screen and (min-width: 960px) {
        min-height: calc(100vh - 260px);
    }

    @media screen and (max-width: 960px) {
        min-height: calc(100vh - 321px);
    }

    .tablist-container {
        background-color: #2c3238;
        border-radius: 4px;
        height: 70px;

        @media screen and (max-width: 960px) {
            margin-top: 24px;
        }

        .tablist-container-general {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;

            .teams {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: variables.$color-white;
                padding-left: 10px;
                padding-right: 10px;

                .teams-image {
                    width: 40px;
                    height: 40px;
                }

                .teams-name {
                    font-size: 22px;
                    margin-bottom: 0;
                    margin-left: 8px;
                    min-width: 200px;

                    @media screen and (max-width: 769px) {
                        display: none;
                    }
                }
            }

            .tablist {
                color: variables.$color-white;

                .tablist-general-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .text-tablist {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.general-teams-container {
    &__teams {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        margin-bottom: 24px;
    }

    &__teams-name {
        font-size: 20px;
        margin-bottom: 0;
    }
}

.teams {
    @media screen and (max-width: 500px) {
        margin-left: 0px;
    }
}

.teams-page-general-information {
    display: flex;
    gap: 20px;

    @media screen and (max-width: variables.$breakpoint-lg) {
        flex-direction: column;
    }

    &__left {
        width: 50%;

        @media screen and (max-width: variables.$breakpoint-lg) {
            width: 100%;
        }
    }

    &__right {
        width: 50%;

        @media screen and (max-width: variables.$breakpoint-lg) {
            width: 100%;
        }
    }
}

@media screen and (max-width: 500px) {
    .container.team-page-section {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .general-teams-container__teams,
    .team-page-section__title,
    .rounds-selector {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .alineaciones-probables-container {
        padding: 0;
    }
}
