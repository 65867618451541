.comparator {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .comparator-tabs {
        margin-bottom: 16px;
    }

    &__select-compare {
        @media (min-width: 768px) {
            display: flex;
            justify-content: flex-end;

            .MuiButtonBase-root {
                width: 250px;
            }
        }
    }

    &__select-players {
        .label-select {
            width: 100%;
        }

        @media (min-width: 768px) {
            display: flex;
            gap: 20px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__mercado {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__mercado-tabla {
        margin-top: 4px;
    }

    &__mercado-grafica {
        margin-top: 4px;
    }

    &__h2 {
        font-size: 22px !important;
        margin-bottom: 16px !important;
        font-weight: 600 !important;
    }

    .comparator__mercado-tabla {
        overflow-x: auto;
    }

    .table-container {
        position: relative;
        overflow-x: auto;
        max-width: 100%;
    }

    /* Estilo para fijar la primera columna */
    table {
        table-layout: fixed;
        width: 100%;
    }

    th:first-child,
    td:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: #fff; /* Cambia el color de fondo según tus preferencias */
        text-align: center;
        border-right: 1px solid #e0e0e0;
    }

    th {
        background: #f5f5f5;
    }

    th:first-child {
        background: #f5f5f5;
    }

    /* Estilo para el resto de las celdas */
    th,
    td {
        width: 150px;
        text-align: center;
        border-right: 1px solid #e0e0e0;
    }

    td {
        white-space: nowrap; /* Evita que el contenido se desborde */
    }

    /* Estilo para las filas impares */
    // tr:nth-child(odd) {
    //     background-color: #f5f5f5; /* Cambia el color de fondo según tus preferencias */
    // }

    &__winner {
        color: #1a892d;
    }

    &__loser {
        color: #d32f2f;
    }

    &__stats-winner {
        color: #1a892d;
        width: auto;
        position: relative;
    }

    &__stats-loser {
        color: #d32f2f;
    }
}

.next-fixture-table-container {
    th,
    td {
        padding: 0;
    }
}
