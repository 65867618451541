.estadisticas-biwenger-container {
    &__header {
        margin-bottom: 20px;
    }

    &__selector {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .fitness-container {
        display: flex;
        gap: 8px;
        border: 1px solid #e0e0e0;
    }
}