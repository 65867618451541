.jugador-fantasy-container {
    .detail-position {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0.4375rem 0;
    }

    @media screen and (min-width: 600px) {
        .detail-position {
            flex-direction: row;
            justify-content: space-evenly;
        }
    }

    .detail-position__box {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        line-height: 1.125rem;
        margin: 0.3125rem 0 0.625rem;
        padding: 0.125rem;
        width: 100%;
    }

    @media screen and (min-width: 600px) {
        .detail-position__box {
            margin: 0rem;
            padding: 1.125rem 0.6875rem;
            width: auto;
        }
    }

    .detail-position__title {
        font-weight: normal;
        text-align: center;
        font-size: 14px;
    }

    .detail-position__position {
        font-weight: 500;
        line-height: 1.125rem;
        text-align: center;
        font-size: 14px;
        margin-bottom: 0;
    }

    .matchfield__campo::before,
    .matchfield__campo::after,
    .matchfield__interior::before,
    .matchfield__interior::after {
        content: '';
        display: block;
    }

    .matchfield__interior {
        border: 1px solid #fff;
        height: 100%;
        position: absolute;
        width: 100%;
    }

    .matchfield__interior::before,
    .matchfield__interior::after {
        border: 1px solid #fff;
        bottom: 0;
        position: absolute;
    }

    .matchfield__interior::before {
        border-radius: 50%;
        bottom: 0;
        height: 0;
        left: 40%;
        padding-bottom: 28%;
        width: 20%;
    }

    .matchfield__interior::after {
        background-color: #22272f;
        border-bottom: 0;
        height: 24%;
        left: 16.5%;
        width: 67%;
    }

    .matchfield__campo {
        background-color: #22272f;
        box-shadow: 0 0 0 4px #22272f;
        display: inline-block;
        height: 177px;
        margin: 10px auto;
        position: relative;
        vertical-align: middle;
        width: 50%;
    }

    .matchfield__campo::before,
    .matchfield__campo::after {
        border: 1px solid #fff;
        margin: 0;
        position: absolute;
        z-index: 2;
    }

    .matchfield__campo::before {
        bottom: 0;
        height: 12%;
        left: 33.5%;
        margin: 0;
        width: 33%;
    }

    .matchfield__campo::after {
        border-bottom-left-radius: 250px;
        border-bottom-right-radius: 250px;
        height: 15%;
        left: 35.5%;
        top: 0;
        width: 29%;
    }

    .position {
        background-color: #fff;
        border: 2px solid #22272f;
        border-radius: 50%;
        box-shadow: 0 0 0 2px #fff;
        height: calc(var(--tm-position-dot) * 2);
        position: absolute;
        width: calc(var(--tm-position-dot) * 2);
        z-index: 2;
    }

    .position--primary {
        --tm-position-dot: 0.5625rem;
    }

    .position--secondary {
        --tm-position-dot: 0.375rem;
    }

    .position--1 {
        left: calc(50% - var(--tm-position-dot));
        top: calc(11rem - var(--tm-position-dot));
    }

    .position--2 {
        left: calc(50% - var(--tm-position-dot));
        top: calc(9.0625rem - var(--tm-position-dot));
    }

    .position--3 {
        left: calc(31% - var(--tm-position-dot));
        top: calc(8.375rem - var(--tm-position-dot));
    }

    .position--3-2 {
        right: calc(31% - var(--tm-position-dot));
        top: calc(8.375rem - var(--tm-position-dot));
    }

    .position--4 {
        left: calc(8% - var(--tm-position-dot));
        top: calc(7.9375rem - var(--tm-position-dot));
    }

    .position--5 {
        right: calc(8% - var(--tm-position-dot));
        top: calc(7.9375rem - var(--tm-position-dot));
    }

    .position--6 {
        left: calc(50% - var(--tm-position-dot));
        top: calc(7.375rem - var(--tm-position-dot));
    }

    .position--7 {
        left: calc(50% - var(--tm-position-dot));
        top: calc(5.5rem - var(--tm-position-dot));
    }

    .position--8 {
        right: calc(8% - var(--tm-position-dot));
        top: calc(4.8125rem - var(--tm-position-dot));
    }

    .position--9 {
        left: calc(8% - var(--tm-position-dot));
        top: calc(4.8125rem - var(--tm-position-dot));
    }

    .position--10 {
        left: calc(50% - var(--tm-position-dot));
        top: calc(3.75rem - var(--tm-position-dot));
    }

    .position--11 {
        left: calc(8% - var(--tm-position-dot));
        top: calc(1.6875rem - var(--tm-position-dot));
    }

    .position--12 {
        right: calc(8% - var(--tm-position-dot));
        top: calc(1.6875rem - var(--tm-position-dot));
    }

    .position--13 {
        left: calc(50% - var(--tm-position-dot));
        top: calc(2.125rem - var(--tm-position-dot));
    }

    .position--14 {
        left: calc(50% - var(--tm-position-dot));
        top: calc(0.625rem - var(--tm-position-dot));
    }

    .played-position {
        display: flex;
        justify-content: center;
    }

    .played-position__position {
        position: absolute;
    }

    .played-position__position--1 {
        left: 45.5%;
        top: 84%;
    }

    .played-position__position--2 {
        left: 45.5%;
        top: 75%;
    }

    .played-position__position--3 {
        left: 21%;
        top: 68%;
        width: 180px;
    }

    .played-position__position--4 {
        height: 3.75rem;
        left: 5%;
        line-height: 3.75rem;
        top: 56%;
        width: 2.5rem;
    }

    .played-position__position--5 {
        height: 3.75rem;
        line-height: 3.75rem;
        right: 5%;
        top: 56%;
        width: 2.5rem;
    }

    .played-position__position--6 {
        left: 26%;
        top: 54%;
        width: 9.375rem;
    }

    .played-position__position--7 {
        left: 35.5%;
        top: 41%;
        width: 5.625rem;
    }

    .played-position__position--8 {
        height: 3.125rem;
        line-height: 3.125rem;
        right: 7%;
        top: 33%;
        width: 3.125rem;
    }

    .played-position__position--9 {
        height: 3.125rem;
        left: 7%;
        line-height: 3.125rem;
        top: 33%;
        width: 3.125rem;
    }

    .played-position__position--10 {
        left: 26%;
        top: 28%;
        width: 9.375rem;
    }

    .played-position__position--11 {
        height: 3.125rem;
        left: 9%;
        line-height: 3.125rem;
        top: 10%;
        width: 3.125rem;
    }

    .played-position__position--12 {
        height: 3.125rem;
        line-height: 3.125rem;
        right: 9%;
        top: 10%;
        width: 3.125rem;
    }

    .played-position__position--13 {
        left: 35.5%;
        top: 15%;
        width: 5.625rem;
    }

    .played-position__position--14 {
        left: 26%;
        top: 2%;
        width: 9.375rem;
    }

    .played-position__pos-box {
        position: absolute;
    }

    .played-position__pos-box--1 {
        left: 40.5%;
        top: 84%;
    }

    .played-position__pos-box--2 {
        left: 40.5%;
        top: 75%;
    }

    .played-position__pos-box--3 {
        left: 40.5%;
        top: 68%;
    }

    .played-position__pos-box--4 {
        left: 5%;
        top: 56%;
    }

    .played-position__pos-box--5 {
        right: 5%;
        top: 56%;
    }

    .played-position__pos-box--6 {
        left: 40.5%;
        top: 53%;
    }

    .played-position__pos-box--7 {
        left: 40.5%;
        top: 40.5%;
    }

    .played-position__pos-box--8 {
        right: 7%;
        top: 33%;
    }

    .played-position__pos-box--9 {
        left: 7%;
        top: 33%;
    }

    .played-position__pos-box--10 {
        left: 40.5%;
        top: 25%;
    }

    .played-position__pos-box--11 {
        left: 9%;
        top: 8%;
    }

    .played-position__pos-box--12 {
        right: 9%;
        top: 8%;
    }

    .played-position__pos-box--13 {
        left: 40.5%;
        top: 12%;
    }

    .played-position__pos-box--14 {
        left: 40.5%;
        top: -12px;
    }

    .played-position__position-box {
        background-color: rgba(0, 0, 0, 0);
        color: #1d75a3;
        cursor: pointer;
        font-family: var(--tm-quaternary-font, 'OSL', 'OSL-fallback', sans-serif);
        font-weight: bold;
        height: 3.125rem;
        text-align: center;
        width: 3.75rem;
    }

    .played-position__outside {
        display: table-cell;
        height: 3.75rem;
        vertical-align: middle;
        width: 3.75rem;
    }

    .played-position__text-box {
        background-color: #fff;
        border: 1px solid #fff;
        display: block;
        margin: 0 auto;
        max-height: 60px;
        max-width: 60px;
        min-height: 15px;
        min-width: 15px;
        position: relative;
    }

    .played-position__additional-circle {
        background-color: #5ca6ff;
        border-radius: 17px;
        color: #fff;
        display: none;
        font-family: var(--tm-quaternary-font, 'OSL', 'OSL-fallback', sans-serif);
        height: 1.0625rem;
        line-height: 1.0625rem;
        position: absolute;
        right: -3px;
        top: -3px;
        width: 1.0625rem;
    }

    .pos-anzeige:hover .played-position__outside .played-position__text-box {
        display: none;
        transition: opacity 300ms ease;
    }

    .content-probability-table {
        display: flex;
        justify-content: space-between;
        margin: 0.125rem 0;
        padding: 0.3125rem;
        text-decoration: none;
    }

    .content-probability-table:nth-child(even) {
        background-color: #f2f2f2;
    }

    .content-probability-table:nth-child(odd) {
        background-color: #fff;
    }

    .content-probability-table:nth-child(even):hover {
        background-color: #e4e4e4;
        cursor: pointer;
        text-decoration: none;
    }

    .content-probability-table:nth-child(odd):hover {
        background-color: #e4e4e4;
        cursor: pointer;
        text-decoration: none;
    }

    .content-probability-table .green-arrow-ten {
        background-image: url('https://tmsi.akamaized.net/pfeile/arrow.svg');
        background-position: right;
        background-repeat: no-repeat;
        display: block;
        margin: 0;
    }

    .content-probability-table .red-arrow-ten {
        background-image: url('https://tmsi.akamaized.net/pfeile/arrow_red.svg');
        background-position: right;
        background-repeat: no-repeat;
        background-size: 0.625rem;
        display: block;
        margin: 0;
        transform: rotate(90deg);
    }

    .content-probability-table .grey-block-ten {
        background-image: url('https://tmsi.akamaized.net/pfeile/equal.svg');
        background-position: right;
        background-repeat: no-repeat;
        background-size: 0.875rem;
        margin-left: 0;
    }

    .content-probability-table__table-topic {
        background-color: #f2f2f2;
        display: flex;
        font-family: var(--tm-secondary-font, 'Oswald-VF', 'Oswald-fallback', sans-serif);
        font-size: 0.875rem;
        justify-content: space-between;
        padding: 0.25rem 0.125rem 0.25rem 0.4375rem;
    }

    .content-probability-table__img {
        margin: 0 0.5rem 0 0.125rem;
        width: 1.875rem;
    }

    .content-probability-table__div {
        display: flex;
    }

    .content-probability-table__div--column {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .content-probability-table__div--row {
        flex-direction: row;
    }

    .content-probability-table__content {
        color: #57585a;
        font-size: 0.75rem;
        font-weight: bold;
    }

    .content-probability-table__content--highlight {
        color: #1d75a3;
    }

    .content-probability-table__content--large {
        align-self: center;
        color: #000;
        font-family: var(--tm-secondary-font, 'Oswald-VF', 'Oswald-fallback', sans-serif);
        font-size: 1.375rem;
        font-weight: bold;
        margin-right: 0.3125rem;
    }

    .content-probability-table__content--signs {
        color: #57585a;
        font-family: var(--tm-secondary-font, 'Oswald-VF', 'Oswald-fallback', sans-serif);
    }

    .quick-fact {
        display: flex;
        flex-direction: column;
        font-family: var(--tm-secondary-font, 'Oswald-VF', 'Oswald-fallback', sans-serif);
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        margin-top: 0.625rem;
        row-gap: 0.625rem;
    }

    @media screen and (min-width: 600px) {
        .quick-fact {
            column-gap: 0.625rem;
            display: grid;
        }
    }

    .quick-fact__link {
        background-color: #1a3151;
        color: #fff;
        text-align: center;
        text-decoration: none !important;
    }

    .quick-fact__headline {
        border-bottom: 0.0625rem dotted #fff;
        color: #fff;
        font-family: var(--tm-secondary-font, 'Oswald-VF', 'Oswald-fallback', sans-serif);
        font-size: var(--tm-secondary-font-size-base);
        padding: 0.5rem;
    }

    .quick-fact__circle {
        border-bottom: 0.1875rem dotted #00aded;
        border-left: 0.1875rem dotted #00aded;
        border-radius: 50%;
        border-right: 0.1875rem dotted #00aded;
        height: 8.625rem;
        margin: 1.25rem auto;
        position: relative;
        width: 8.625rem;
    }

    .quick-fact__circle::before {
        border: 0.125rem dashed #fff;
        border-radius: 50%;
        bottom: -5.1875rem;
        content: '';
        height: 9.3125rem;
        position: absolute;
        transform: translate(-50%, -50%);
        width: 9.3125rem;
    }

    .quick-fact__circle::after {
        border-left: 0.125rem dotted #ddd;
        border-radius: 50%;
        bottom: 0.125rem;
        content: '';
        height: 8.125rem;
        left: 0.1875rem;
        position: absolute;
        transform: rotate(60deg);
        width: 8.125rem;
    }

    .quick-fact__icon {
        background-color: #f2f2f2;
        border: 0.125rem solid #f2f2f2;
        border-radius: 50%;
        height: 2.5rem;
        position: absolute;
        width: 2.5rem;
        z-index: 1;
    }

    .quick-fact__icon--0 {
        right: -0.875rem;
        top: -0.375rem;
    }

    .quick-fact__icon--1 {
        right: -2.0625rem;
        top: 2.1875rem;
    }

    .quick-fact__icon--2 {
        right: -1.5625rem;
        top: 5rem;
    }

    .quick-fact__icon--top {
        border: 0.125rem solid #dcb24e !important;
    }

    .quick-fact__content {
        display: flex;
        flex-direction: column;
        font-family: var(--tm-secondary-font, 'Oswald-VF', 'Oswald-fallback', sans-serif);
        text-align: center;
        transform: translate(0, 48%);
    }

    .quick-fact__content--flop-text {
        font-size: 2.5rem;
    }

    .quick-fact__content--top-text {
        color: #dcb24e;
    }

    .quick-fact__content--small {
        color: #ddd;
        font-size: var(--tm-secondary-font-size-medium);
        line-height: 1rem;
    }

    .quick-fact__content--large {
        font-size: 3.25rem;
    }

    .quick-fact__icon-wrapper {
        color: #00aded;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 2.25rem;
    }

    .quick-fact__icon-wrapper .flaggenrahmen {
        border-radius: 50%;
    }

    .quick-fact__footnote {
        cursor: pointer;
        font-size: var(--tm-secondary-font-size-small);
        margin: 0.9375rem 0.625rem 1.25rem;
    }

    @media screen and (min-width: 600px) {
        .quick-fact__footnote {
            margin: 0.9375rem 0.625rem 0.625rem;
        }
    }

    .logo {
        height: 1.5rem;
        margin-top: 0.3125rem;
        width: 1.5rem;
    }

    .tm-player-market-value-development-container {
        border-bottom: 0;
        border-right: 0;
        margin-bottom: 0.625rem;
    }

    @media screen and (min-width: 600px) {
        .tm-player-market-value-development-container {
            margin-bottom: 0rem;
        }
    }

    .tm-player-market-value-development-container .highcharts-tooltip {
        display: none;
    }

    @media screen and (min-width: 600px) {
        .tm-player-market-value-development-container .highcharts-tooltip {
            display: block;
        }
    }

    .tm-player-market-value-development__current-and-max {
        display: flex;
        justify-content: space-between;
        margin: 0.625rem auto;
        width: calc(100% - 1.25rem);
    }

    .tm-player-market-value-development__current-value {
        display: flex;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    .tm-player-market-value-development__link {
        align-items: center;
        display: flex;
        margin-left: 0.3125rem;
    }

    .tm-player-market-value-development__max {
        text-align: right;
    }

    .tm-player-market-value-development__max-value {
        font-size: 0.75rem;
        line-height: 1.0625rem;
    }

    .tm-player-market-value-development__max-value,
    .tm-player-market-value-development__current-value {
        font-weight: bold;
    }

    .tm-player-market-value-development__graph {
        margin: 0 auto 0.3125rem;
        width: calc(100% - 1.25rem);
    }

    .tm-player-market-value-development__graph .highcharts-axis {
        display: none;
    }

    .tm-player-market-value-development__graph svg {
        overflow: visible;
    }

    .tm-player-market-value-development__update {
        margin: 0 auto 0.3125rem;
        text-align: right;
        width: calc(100% - 1.25rem);
    }

    .tm-player-transfer-history-grid {
        grid-template: 'season emblem club fee' 1.375rem 'season emblem date fee' 1.4375rem/3.125rem 2.8125rem auto 6.25rem;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid {
            grid-template: 'season date clubold clubnew marketvalue fee link' minmax(1.75rem, auto) / 3.5625rem 5.5rem 1fr 1fr 5.4375rem 4.6875rem 1.0625rem;
        }
    }

    .tm-player-transfer-history-grid__season {
        background-color: #ddd;
        border-bottom: 0.125rem solid #fff;
        font-weight: bold;
        grid-area: season;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__season {
            background-color: unset;
            font-weight: normal;
            grid-area: unset;
            justify-content: center;
        }
    }

    .tm-player-transfer-history-grid__date {
        border-bottom: 0.125rem solid #ddd;
        grid-area: date;
        justify-content: start !important;
        padding-bottom: 0.3125rem;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__date {
            border-bottom: 0;
            justify-content: center !important;
            padding-bottom: 0;
        }
    }

    .tm-player-transfer-history-grid__hint {
        align-items: center;
        display: flex;
        grid-area: hint;
        justify-content: flex-end;
        padding: 0 0.25rem;
    }

    .tm-player-transfer-history-grid__new-club {
        grid-area: club;
        margin-top: 0.3125rem;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__new-club {
            grid-area: initial;
            margin-top: 0;
        }
    }

    .tm-player-transfer-history-grid__logo-link {
        align-items: center;
        border-bottom: 0.125rem solid #ddd !important;
        display: flex;
        height: 2.8125rem;
        justify-content: center;
        padding-right: 0.1875rem;
    }

    .tm-player-transfer-history-grid__logo-link > img {
        height: 1.875rem;
        width: 1.875rem;
    }

    .tm-player-transfer-history-grid__flag {
        border: 0.0625rem solid #57585a;
        height: auto;
        margin: 0 0.25rem 0 0;
        width: 0.875rem;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__flag {
            grid-area: rowflag;
            margin: 0 0.5rem;
        }
    }

    .tm-player-transfer-history-grid__club-logo {
        height: auto;
        width: 0.9375rem;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__club-logo {
            grid-area: rowlogo;
        }
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__club-link {
            grid-area: rowlink;
        }
    }

    .tm-player-transfer-history-grid__fee {
        align-items: end !important;
        border-bottom: 0.125rem solid #ddd;
        flex-direction: column;
        font-weight: bold;
        grid-area: fee;
        justify-content: center;
        padding-right: 0.3125rem;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__fee {
            align-items: flex-end !important;
            font-weight: unset;
            justify-content: center !important;
            line-height: 1.0625rem;
            text-align: right;
        }
    }

    .tm-player-transfer-history-grid__season,
    .tm-player-transfer-history-grid__date,
    .tm-player-transfer-history-grid__old-club,
    .tm-player-transfer-history-grid__new-club,
    .tm-player-transfer-history-grid__market-value,
    .tm-player-transfer-history-grid__fee,
    .tm-player-transfer-history-grid__link {
        align-items: center;
        display: flex;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__season,
        .tm-player-transfer-history-grid__date,
        .tm-player-transfer-history-grid__old-club,
        .tm-player-transfer-history-grid__new-club,
        .tm-player-transfer-history-grid__market-value,
        .tm-player-transfer-history-grid__fee,
        .tm-player-transfer-history-grid__link {
            border-bottom: 0.0625rem solid #e4e4e4;
            border-right: 0.0625rem solid #e4e4e4;
            padding: 0 0.25rem;
        }
    }

    .tm-player-transfer-history-grid__old-club,
    .tm-player-transfer-history-grid__new-club {
        font-weight: bold;
        justify-content: flex-start;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__old-club,
        .tm-player-transfer-history-grid__new-club {
            display: grid;
            grid-template: 'rowlogo rowflag rowlink' 100%/0.9375rem 1.875rem auto;
        }
    }

    .tm-player-transfer-history-grid__season,
    .tm-player-transfer-history-grid__date,
    .tm-player-transfer-history-grid__link {
        justify-content: center;
    }

    .tm-player-transfer-history-grid__market-value {
        justify-content: flex-end;
    }

    .tm-player-transfer-history-grid--heading {
        background: #f2f2f2;
        font-weight: bold !important;
    }

    .tm-player-transfer-history-grid--heading .tm-player-transfer-history-grid__season {
        font-weight: bold !important;
    }

    .tm-player-transfer-history-grid--heading .tm-player-transfer-history-grid__old-club,
    .tm-player-transfer-history-grid--heading .tm-player-transfer-history-grid__new-club {
        display: flex;
    }

    .tm-player-transfer-history-grid--sum {
        color: #57585a;
        grid-template: 'hint fee' 1.75rem / auto 4.6875rem;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid--sum {
            grid-template: 'hint fee fee' 1.75rem / auto 4.6875rem 1.0625rem;
        }
    }

    .tm-player-transfer-history-grid--sum .tm-player-transfer-history-grid__fee {
        border-bottom: 0;
        border-right: 0;
    }

    .tm-player-transfer-history-grid--divider {
        align-items: center;
        border-bottom: 0.0625rem solid #e4e4e4;
        color: #57585a;
        padding: 0.3125rem;
    }

    .tm-player-transfer-history-grid__link {
        align-items: flex-end;
        border-right: 0;
        display: flex;
        flex-direction: column;
    }

    .tm-player-transfer-history-grid__link--placeholder {
        border-bottom: 0.0625rem solid #e4e4e4;
        border-right: 0;
    }

    @media screen and (min-width: 600px) {
        .tm-player-transfer-history-grid__link::after {
            border-right: 0.0625rem solid #00aded;
            border-top: 0.0625rem solid #00aded;
            content: '';
            display: block;
            height: 0.375rem;
            margin-right: 0.1875rem;
            transform: rotate(45deg);
            width: 0.375rem;
        }
    }

    .data-header {
        background-image: linear-gradient(to bottom, #fff 0%, #fff 100%);
        border: 0.0625rem solid #ddd;
        display: grid;
        grid-template: 'name name' 3.125rem 'image infobox' auto 'image details' auto 'marketvalue details' auto 'triumphs triumphs' auto 'watchlist watchlist' auto/7.125rem auto;
        height: auto;
        margin: 0.3125rem 0.3125rem 0;
        padding: 0;
        position: relative;
        z-index: 9991;
    }

    @media screen and (min-width: 769px) {
        .data-header {
            background-image: linear-gradient(to bottom, #fff 0%, #ddd 30%, #ddd 68.5%, #fff 68.5%);
            grid-template: 'name name name name name void infobox infobox' 1fr 'image void3 triumphs triumphs triumphs void infobox infobox' auto 'image void3 triumphs triumphs triumphs void void2 void2' 1.25rem 'image void3 triumphs triumphs triumphs void marketvalue marketvalue' 0.625rem 'image void3 void4 void4 void4 void marketvalue marketvalue' 0.8125rem 'image void3 void4 void4 void4 void marketvalue marketvalue' 0.75rem 'image void3 details details details void marketvalue marketvalue' 4.25rem/8.9375rem 0.875rem auto;
            height: 16.0625rem;
            padding: 0.25rem 0.625rem 0.625rem;
            z-index: 3;
        }
    }

    .data-header__verified-logo {
        margin-left: 0.3125rem;
    }

    .data-header__profile-image {
        display: block;
    }

    .data-header__headline-container {
        align-items: center;
        background-image: linear-gradient(to bottom, #fff 0%, #ddd 60%);
        display: inline-flex;
        font-size: 1.875rem;
        grid-area: name;
        margin: 0;
        padding-left: 0.3125rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__headline-container {
            background-image: unset;
            display: flex;
            padding-left: 0;
        }
    }

    .data-header__headline-wrapper {
        color: #00193f;
        font-family: var(--tm-quaternary-font, 'OSL', 'OSL-fallback', sans-serif);
        font-size: 1.875rem;
        font-weight: normal;
        margin: 0 0 0 0.125rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__headline-wrapper {
            font-size: 2.375rem;
        }
    }

    .data-header__headline-wrapper--oswald {
        font-family: var(--tm-secondary-font, 'Oswald-VF', 'Oswald-fallback', sans-serif);
        font-size: 1.4375rem;
        font-variation-settings: 'wght' 700;
    }

    @media screen and (min-width: 769px) {
        .data-header__headline-wrapper--oswald {
            font-size: 2.375rem;
        }
    }

    .data-header__btn-favorite {
        background-color: rgba(0, 0, 0, 0);
        border: none;
        display: inline-flex;
    }

    .data-header__badge-container {
        align-items: flex-end;
        border-top: 0.0625rem solid #ddd;
        display: inline-flex;
        grid-area: triumphs;
        justify-content: flex-start;
        margin-top: 0.1875rem;
        padding: 0.1875rem 0 0.1875rem 0.3125rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__badge-container {
            border-top: unset;
            margin-top: 0;
            min-height: 5rem;
            padding: 0;
        }
    }

    .data-header__info-box {
        align-items: center;
        grid-area: details;
        line-height: 1.1875rem;
        margin: 0.125rem 0 0 0.3125rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__info-box {
            align-items: center;
            display: inline-grid;
            margin: 0;
        }
    }

    .data-header__info-box--as-big-box {
        grid-area: auto;
    }

    @media screen and (min-width: 769px) {
        .data-header__info-box--as-big-box {
            grid-area: inherit;
        }
    }

    .data-header__shirt-number {
        font-weight: bold;
    }

    .data-header__details {
        align-self: center;
        display: flex;
        flex-direction: column;
    }

    @media screen and (min-width: 769px) {
        .data-header__details {
            display: grid;
            grid-gap: 0 0.625rem;
            grid-template-columns: repeat(3, auto);
        }
    }

    .data-header__items {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .data-header__label {
        align-self: flex-start;
        color: #333;
        display: block;
    }

    .data-header__content {
        color: #333;
        font-weight: bold;
    }

    .data-header__content--vertical-aligned {
        align-items: center;
        display: inline-flex;
    }

    .data-header__content--tiny-logo {
        margin: 0 2px;
        vertical-align: text-bottom;
    }

    .data-header__content .flagge,
    .data-header__content .flaggenrahmen {
        margin: 0 0.125rem;
    }

    .data-header__content--highlight {
        color: #1d75a3;
    }

    .data-header__content--thin {
        font-weight: normal;
    }

    .data-header__profile-container {
        align-items: flex-start;
        background: #fff;
        display: inline-flex;
        grid-area: image;
        justify-content: center;
        margin: 0.1875rem 0.1875rem 0;
        position: relative;
        width: 6.875rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__profile-container {
            align-items: flex-end;
            margin: 0;
            width: auto;
        }
    }

    .data-header__profile-container--national-team {
        background-color: rgba(0, 0, 0, 0);
    }

    .data-header__profile-container--national-team > img {
        border: 0.1875rem solid #f2f2f2;
        border-radius: 100%;
    }

    .data-header__box__flag {
        border: 0.0625rem solid #d2e3ed;
        border-radius: 50%;
    }

    .data-header__box--big {
        align-items: center;
        background-color: #fff;
        border: 0;
        border-bottom: 0.0625rem solid #ddd;
        display: inline-flex;
        grid-area: infobox;
        justify-self: start;
        margin: 0.3125rem 0 0.3125rem 0.3125rem;
        max-height: 7.5rem;
        padding: 0 0 0.3125rem;
        width: calc(100% - 0.625rem);
    }

    @media screen and (min-width: 769px) {
        .data-header__box--big {
            border: 0.0625rem solid #ddd;
            grid-column: 5/10;
            justify-self: end;
            margin: 0.625rem 0 0;
            padding: 0.625rem 0.9375rem 0.625rem 0.625rem;
            width: unset;
        }
    }

    .data-header__box--bare {
        background: rgba(0, 0, 0, 0);
        border: 0;
        padding: 0;
    }

    .data-header__box--small {
        align-items: center;
        background-color: #5ca6ff;
        border: 0.0625rem solid #ddd;
        color: #fff;
        display: inline-flex;
        font-family: var(--tm-quaternary-font, 'OSL', 'OSL-fallback', sans-serif);
        font-size: 1.5rem;
        grid-area: marketvalue;
        height: 1.75rem;
        justify-content: flex-end;
        margin: 0 0.125rem 0.125rem;
        min-width: 6.75rem;
        padding: 0 0.3125rem;
        place-self: flex-end flex-end;
    }

    @media screen and (min-width: 769px) {
        .data-header__box--small {
            align-items: unset;
            height: 5.375rem;
            margin: 0;
            min-width: 14.375rem;
            padding: 0.3125rem 1.5625rem;
        }
    }

    .data-header__box--small span:first-child {
        font-size: 1rem;
    }

    .data-header__box--link {
        background-color: #f2f2f2;
        border: 0.125rem solid #fff;
        color: #1d75a3;
        display: inline-flex;
        flex-direction: column;
        grid-area: marketvalue;
        justify-content: center;
        justify-self: flex-end;
        line-height: 1rem;
        margin-left: 0.1875rem;
        min-width: 6.875rem;
        padding: 0.25rem 0.1875rem;
        transform: translateY(-0.125rem);
    }

    @media screen and (min-width: 769px) {
        .data-header__box--link {
            background-color: #fff;
            border: 0.0625rem solid #ddd;
            display: flex;
            line-height: 1.0625rem;
            min-width: 15.625rem;
            padding: 0.125rem 0.9375rem;
        }
    }

    .data-header__box--link:hover {
        background-color: #f2f2f2;
        text-decoration: none;
    }

    .data-header__box--link .data-header__label,
    .data-header__box--link .data-header__content {
        color: #1d75a3;
    }

    .data-header__box__club-link {
        display: flex;
    }

    .data-header__toggle {
        background-color: #f2f2f2;
        border-radius: 0 1.25rem 0 0;
        bottom: 0;
        height: 1.375rem;
        left: 0;
        position: absolute;
        width: 1.75rem;
    }

    .data-header__toggle--active .data-header__list-clubs {
        display: block;
    }

    .data-header__toggle:hover {
        background-color: #f2f2f2;
    }

    .data-header__toggle-icon {
        color: #00aded;
        cursor: pointer;
        display: inline-block;
        font-family: var(--tm-secondary-font, 'Oswald-VF', 'Oswald-fallback', sans-serif);
        font-size: 1.6875rem;
        font-weight: bold;
        height: 1.375rem;
        line-height: 1.6875rem;
        position: relative;
        text-align: center;
        width: 1.375rem;
    }

    .data-header__toggle-icon img {
        vertical-align: bottom;
    }

    .data-header__list-clubs {
        background-color: #f2f2f2;
        border: 0.0625rem solid #ddd;
        border-top: 0.0625rem solid #f2f2f2;
        display: none;
        font-family: var(--tm-secondary-font, 'Oswald-VF', 'Oswald-fallback', sans-serif);
        left: -0.0625rem;
        line-height: 1.8;
        padding: 0.25rem 0.5rem;
        position: absolute;
        text-align: left;
        top: 1.375rem;
        width: 15rem;
        z-index: 9999;
    }

    .data-header__list-clubs img {
        margin-bottom: -0.125rem;
        margin-right: 0.4375rem;
    }

    .data-header__list-clubs span {
        display: inline-block;
        font-size: 0.8125rem;
        line-height: 1.375rem;
    }

    .data-header__link-headline {
        font-size: 0.75rem;
        font-weight: normal;
        margin-bottom: 0.1875rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__link-headline {
            font-size: 0.875rem;
            font-weight: bold;
        }
    }

    .data-header__club-info {
        display: inline-flex;
        flex-direction: column;
        line-height: 1.125rem;
        padding: 0 0.5rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__club-info {
            display: block;
        }
    }

    .data-header__club {
        color: #1a3151;
        font-size: 1rem;
        font-weight: bold;
    }

    .data-header__ribbon {
        height: 4.6875rem;
        overflow: hidden;
        position: absolute;
        right: -0.3125rem;
        text-align: right;
        top: -0.3125rem;
        width: 4.6875rem;
    }

    .data-header__ribbon span {
        background: linear-gradient(#00aded 0%, #22272f 100%);
        color: #fff;
        display: block;
        font-family: var(--tm-primary-font, 'Source Sans Pro', 'Source Sans Pro-fallback', sans-serif);
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 1.25rem;
        position: absolute;
        right: -1.3125rem;
        text-align: center;
        text-transform: uppercase;
        top: 1.1875rem;
        transform: rotate(45deg);
        width: 6.25rem;
    }

    .data-header__ribbon span::after,
    .data-header__ribbon span::before {
        border-bottom: 0.1875rem solid rgba(0, 0, 0, 0);
        border-top: 0.1875rem solid #22272f;
        content: '';
        position: absolute;
        top: 100%;
    }

    .data-header__ribbon span::before {
        border-left: 0.1875rem solid #22272f;
        border-right: 0.1875rem solid rgba(0, 0, 0, 0);
        left: 0;
    }

    .data-header__ribbon span::after {
        border-left: 0.1875rem solid rgba(0, 0, 0, 0);
        border-right: 0.1875rem solid #22272f;
        right: 0;
    }

    .data-header__two-value-grid {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: start;
    }

    .data-header__market-value-wrapper {
        color: #fff;
        font-size: 1.5rem;
        text-align: right;
    }

    @media screen and (min-width: 769px) {
        .data-header__market-value-wrapper {
            font-size: 3.875rem;
        }
    }

    .data-header__market-value-wrapper:hover {
        text-decoration: none;
    }

    .data-header__market-value-wrapper span:first-child {
        color: #fff;
        font-size: 1rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__market-value-wrapper span:first-child {
            font-size: 1.875rem;
        }
    }

    .data-header__last-update {
        display: none;
        font-size: 0.6875rem;
        line-height: 1;
    }

    @media screen and (min-width: 769px) {
        .data-header__last-update {
            display: block;
            font-family: var(--tm-septenary-font, 'SourceSansPro-VF', sans-serif);
        }
    }

    .data-header__league {
        display: inline-block;
    }

    .data-header__league-box {
        max-width: 10rem;
    }

    .data-header__league-link {
        display: flex;
        font-weight: normal;
        line-height: 0.9375rem;
        margin-left: 0.125rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__league-link {
            font-weight: bold;
        }
    }

    .data-header__league-link img {
        align-self: center;
        height: 0.5625rem;
        margin: 0 0.25rem 0 0;
        width: 0.875rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__league-link img {
            height: auto;
            margin: 0 0.125rem 0 0;
            width: auto;
        }
    }

    .data-header__success-data {
        align-items: center;
        display: inline-block;
        height: 2.375rem;
        margin: 0;
        min-width: 2.8125rem;
        padding: 0.25rem;
        position: relative;
    }

    @media screen and (min-width: 769px) {
        .data-header__success-data {
            height: 5rem;
            margin: 0 0 0 1.125rem;
            padding: 0;
        }
    }

    .data-header__success-data:first-child {
        margin-left: 1.375rem;
    }

    @media screen and (min-width: 769px) {
        .data-header__success-data:first-child {
            margin: 0;
        }
    }

    .data-header__success-data img {
        height: 1.875rem;
        width: auto;
    }

    @media screen and (min-width: 769px) {
        .data-header__success-data img {
            height: auto;
            max-height: 5.375rem;
        }
    }

    .data-header__success-data--flag {
        border: 0.1875rem solid #fff;
        border-radius: 50%;
    }

    .data-header__success-more {
        bottom: -0.125rem;
        filter: drop-shadow(0 0 0.5625rem rgb(255, 255, 255));
        font-family: var(--tm-septenary-font, 'SourceSansPro-VF', sans-serif);
        font-variation-settings: 'wght' 400;
        left: -0.25rem;
        margin: 0 0 0.1875rem 0.4375rem;
        position: relative;
        transition: all 250ms ease;
    }

    .data-header__success-more:hover {
        color: #5ca6ff;
        font-variation-settings: 'wght' 700;
        text-decoration: none;
    }

    .data-header__success-more::after {
        border-right: 3px solid #5ca6ff;
        border-top: 3px solid #5ca6ff;
        bottom: 1px;
        content: '';
        display: inline-block;
        height: 12px;
        left: -7px;
        margin: 0.625rem;
        position: absolute;
        transform: rotate(45deg);
        width: 12px;
    }

    @media screen and (min-width: 1034px) {
        .data-header__success-more::after {
            bottom: -5px;
            left: 0;
        }
    }

    @media screen and (min-width: 769px) {
        .data-header__success-more {
            margin: 0 0 0 1.125rem;
        }
    }

    .data-header__success-number {
        background-color: #5ca6ff;
        border: 0.125rem solid #fff;
        border-radius: 0.625rem;
        bottom: 0.0625rem;
        color: #fff;
        font-family: var(--tm-quaternary-font, 'OSL', 'OSL-fallback', sans-serif);
        font-size: 0.75rem;
        font-weight: bold;
        height: 1.25rem;
        line-height: 0.9375rem;
        position: absolute;
        right: 0.5rem;
        text-align: center;
        width: 1.25rem;
        z-index: 4;
    }

    @media screen and (min-width: 769px) {
        .data-header__success-number {
            border-radius: 1.5625rem;
            bottom: -0.25rem;
            font-size: 1.125rem;
            height: 1.875rem;
            line-height: 1.5625rem;
            right: -0.25rem;
            width: 1.875rem;
        }
    }

    .data-header .dataErfolge {
        padding: 0 !important;
    }

    .data-header .dataErfolge > .dataErfolg:first-child {
        margin-left: 0.4375rem;
    }

    .data-header .modal-trigger {
        border: 0.125rem solid #fff;
    }

    .data-header__watchlist {
        background-color: #fff;
        border-top: 0.0625rem solid #ddd;
        display: flex;
        grid-area: watchlist;
        height: 2.5rem;
        justify-content: center;
        text-align: center;
    }

    .data-header__watchlist .watchlistLink {
        font-family: var(--tm-primary-font, 'Source Sans Pro', 'Source Sans Pro-fallback', sans-serif);
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 2.5rem;
        margin-left: 0.1875rem;
    }

    .data-header__watchlist .watchlistButtonClass {
        cursor: pointer;
        height: 2.1875rem;
        width: 2.1875rem;
    }

    .watchlistInfoAnzahl {
        background-color: #f2f2f2;
        border: 0.0625rem solid #ddd;
        clear: both;
        font-weight: bold;
        margin: 0.3125rem 0;
        padding: 0.25rem;
    }

    .sucessPlayerToWatchlist,
    .sucessMatchToWatchlist,
    .sucessPlayerFromWatchlist,
    .sucessMatchFromWatchlist,
    .sucessNoticeUpdate {
        clear: both;
        color: #fff;
        display: none;
        font-size: 0.75rem;
        font-weight: bold;
        margin-bottom: -0.3125rem;
        margin-top: 0.3125rem;
        padding: 0.5rem 0.4375rem 0.4375rem;
    }

    .sucessPlayerToWatchlist,
    .sucessMatchToWatchlist {
        background-color: #749f18;
    }

    .sucessPlayerFromWatchlist,
    .sucessMatchFromWatchlist {
        background-color: #b80718;
    }

    .sucessNoticeUpdate {
        background-color: #1d75a3;
    }

    @media screen and (min-width: 600px) {
        .dataBildWatchlistInfo {
            display: block !important;
        }
    }

    .dataBildWatchlistInfo img {
        height: 6.25rem;
        margin-left: 0.625rem;
        width: auto !important;
    }

    @media screen and (min-width: 600px) {
        .dataBildWatchlistInfo img {
            height: auto;
            margin-left: 0;
            width: 6.875rem !important;
        }
    }

    .dataBildWatchlistInfo .bildquelle {
        margin-top: -0.9375rem;
    }

    .dataBild .bildquelle {
        margin-top: -0.75rem;
    }

    @media screen and (min-width: 600px) {
        #watchlistNoticeHeadline {
            font-size: 1.625rem;
            padding-bottom: 0.3125rem;
        }
    }

    #watchlistInfo div.trennbalken {
        background-color: #00aded;
        height: 0.1875rem;
        margin-bottom: 0.625rem;
        width: 100%;
    }

    #saveWatchlistButton {
        height: 2.5rem;
        margin: 1.25rem 0;
    }

    @media screen and (min-width: 769px) {
        #saveWatchlistButton {
            height: inherit;
            margin: 0;
        }
    }

    .dataDaten .dataValue {
        font-weight: bold;
    }

    .dataDaten .flaggenrahmen {
        margin: 0 0.125rem;
    }

    .addWatchlistButton {
        background: #fff url('/images/add_plus.png');
    }

    .deleteWatchlistButton {
        background: #fff url('/images/delete_watchlist.png');
    }

    .addWatchlistButton:focus,
    .deleteWatchlistButton:focus {
        outline: #fff auto 0;
    }

    .info-table--right-space {
        grid-template-columns: 2fr 3fr;
    }

    .info-table {
        border-right: 0.0625rem solid #ddd;
        display: grid;
        padding: 0.3125rem 0.9375rem;
        font-size: 14px;
    }

    .info-table__content {
        color: #333;
        font-family: var(--tm-primary-font, 'Source Sans Pro', 'Source Sans Pro-fallback', sans-serif);
        font-size: var(--tm-secondary-font-size-small);
        line-height: 1.875rem;
        line-height: 1.5rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0);
    }

    .content-box-subheadline {
        background-color: #f9f9f9;
        border-bottom: 0.0625rem solid #fff;
        color: #333;
        display: flex;
        font-family: var(--tm-primary-font, 'Source Sans Pro', 'Source Sans Pro-fallback', sans-serif);
        font-size: 0.75rem;
        font-variation-settings: var(--tm-secondary-font-bold);
        font-weight: bold;
        justify-content: space-between;
        line-height: 1.5rem;
        padding: 0 0.625rem;
    }

    .show-for-small,
    .show-for-medium,
    .show-for-medium-down,
    .hide-for-large,
    .hide-for-large-up,
    .show-for-xlarge,
    .show-for-print {
        display: none !important;
    }

    .social-media-toolbar__icons {
        gap: 4px;
        display: flex;
        justify-content: center;
    }

    .info-table__content--bold {
        font-weight: 500;
    }

    .svelte-gfmgwx {
        font-size: 14px;

        a {
            font-size: 20px;
            font-weight: 500;

            img {
                display: none;
            }
        }
    }
}
