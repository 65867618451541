@use '../shared/variables';

.resumen-fantasy {
    .filters {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin-bottom: 20px;

        .filters-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;
            background-color: rgb(245, 245, 245);
            cursor: pointer;
        }

        .filters-content {
            padding: 8px;
        }
    }

    .tier-section {
        .tier-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: rgb(245, 245, 245);
            padding: 0 8px;
            border-radius: 4px;
            cursor: pointer;
        }

        .tier-players {
            padding-top: 8px;
            display: flex;
            flex-wrap: wrap;
        }

        .no-players {
            text-align: center;
            padding: 10px;
            color: #888;
            font-size: 14px;
        }
    }

    .player-resumen-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.player-resumen-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 4px;

    @media screen and (max-width: variables.$breakpoint-sm) {
        width: 20%;
        padding-left: 4px;
        padding-right: 4px;
    }
}

.resumen-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
    width: 70px;
    text-align: center;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: variables.$breakpoint-sm) {
        margin-bottom: 16px;
    }

    .fixture-score-container {
        width: 100%;

        .fixture-score-container__color {
            width: 100% !important;
        }
    }

    // Iconos de posición, equipo y estado con posición absoluta
    &__icon {
        position: absolute;
        width: 15px;
        height: 15px;

        &--position {
            top: 55px;
            left: 5px;
        }

        &--homeaway {
            top: 0px;
            right: 5px;
        }

        &--team {
            top: 0px;
            left: 3px;
        }

        &--status {
            top: 50px;
            right: 5px;
        }
    }

    &__name {
        font-size: 12px;
        font-weight: normal;
        margin-top: 2px;
        margin-bottom: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%; // Asegura que el texto no desborde el contenedor

        &--clickable {
            cursor: pointer;
            color: #007bff;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
