@use "../shared/variables";

.market-values-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    border: none;
    box-shadow: 24px;
    border-radius: 4px;
    padding: 10px;
    z-index: 99999999;
    background-color: #fff;
    height: 625;

    @media screen and (max-width: 519px) {
        width: 90%;
        margin: auto;
        top: 50%;
    }
}
.market-values-player-modal {
    .react-tabs__tab-list {
        display: flex;
        width: 100%;
        .react-tabs__tab {
            margin-top: 10px;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 32px;
        }

        .react-tabs__tab--selected {
            background: variables.$primary-color;
        }
    }
    .market-values-player-modal__content {
        &__header {
            border-bottom: 1px solid variables.$color-border;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 70px;

            &__player {
                display: flex;
                align-items: center;

                &__image {
                    border-radius: 4px;
                }
                &__name {
                    margin-bottom: 0;
                    font-size: 16px;
                    margin-left: 5px;
                }
                &__team {
                    margin-bottom: 0;
                    font-size: 16px;
                    margin-left: 5px;
                }

                &__data-container {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }

                &__image-container {
                    display: flex;
                }
            }
        }

        &__body {
            padding: 10px;

            &__content {
                .go-to-see-player-stats-container {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 5px;
                    margin-top: 5px;
                    a {
                        color: variables.$color-clickable-link;
                        font-size: 14px;
                        text-decoration: underline;
                    }
                }
                &__row {
                    height: 397px;
                    overflow-y: auto;
                    .market-values-table {
                        width: 100%;
                        border-radius: 4px;
                        border: 1px solid variables.$color-border;

                        .header-th {
                            text-align: center;
                        }

                        .body-content-td {
                            text-align: center;
                            font-weight: 400;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

table.player-market-values-table {
    tr {
        td,
        th {
            font-size: 13px !important;
            text-align: center;
            padding: 10px 5px !important;

            p {
                font-size: 13px !important;
            }
        }
    }

    .player-market-presentation-card {
        &__image {
            display: flex;
        }
    }
}

div.player-market-dialog .MuiDialog-paper {
    margin: 5px !important;
    height: 650px !important;
    width: 500px !important;

    @media screen and (max-width: variables.$breakpoint-md) {
        width: 95% !important;
    }
}
