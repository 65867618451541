.jugador-page {
    background-color: #fafafa;

    .jugador-fantasy-title {
        font-size: 14px;
        text-align: left;
        background: #f9f9f9;
        padding: 4px 8px;
    }

    .player-stats-container {
        border-radius: 2px;
        text-align: center;
        background: #fff;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);

        .player-stats-header {
            font-size: 16px;
            font-weight: 400;
        }

        .player-stats-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
            margin-bottom: 24px;
            padding: 0 8px;
        }

        .player-stat-item {
            justify-content: space-between;
            text-align: center;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ddd;
            font-size: 14px;

            .player-stat-icon {
                color: #007fff;
                font-size: 24px;
            }

            .player-stat-label {
                font-size: 14px;
                color: #555;
                gap: 4px;
            }

            .player-stat-value {
                font-size: 1.1rem;
                color: #007fff;
                font-weight: bold;
            }
        }

        .player-stats-progress {
            display: flex;
            justify-content: space-around;
            margin-top: 24px;
        }

        .player-stats-button-container {
            margin-top: 24px;
        }

        .player-stats-button {
            background-color: #007fff;
            color: #fff;
            border: none;
            padding: 10px 16px;
            border-radius: 4px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                background-color: #005bb5;
            }
        }
    }

    .fantasy-laliga-section {
        margin-top: 16px;
    }

    .fantasy-laliga-container {
        background: #f9fafc;
        border-radius: 8px;
        padding: 16px 0;
        width: 100%;
    }

    .fantasy-laliga-title {
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        margin-bottom: 16px;
    }

    .fantasy-laliga-grid {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
    }

    .fantasy-laliga-stat-item {
        background: #fff;
        border-radius: 8px;
        text-align: center;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1 / 1;
        transition: transform 0.2s ease-in-out;
        width: 100px;
        height: 100px;

        @media screen and (max-width: 576px) {
            width: calc((100% - 12px) / 3);
        }

        &:hover {
            transform: translateY(-4px);
        }
    }

    // 🟢 Ícono
    .fantasy-laliga-icon {
        margin-bottom: 8px;
        font-size: 2rem;
        color: #007fff;
    }

    // 🔢 Valor destacado
    .fantasy-laliga-value {
        font-size: 1.8rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 4px;
    }

    // 📝 Descripción
    .fantasy-laliga-label {
        font-size: 0.875rem;
        color: #777;
    }

    .mercado-fantasy-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 16px;
    }

    .mercado-info-item {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 4px 8px;
        border-radius: 2px;
        gap: 4px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    }

    .mercado-info-icon {
        color: #007fff;
    }

    .mercado-info-title {
        font-size: 14px;
        font-weight: 400;
    }

    .mercado-info-value {
        font-size: 16px;
        font-weight: 500;
        margin-left: auto;
    }

    .player-page__card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border-radius: 2px;
        padding: 16px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        margin: 0 auto;
        text-align: center;
        margin-bottom: 16px;

        &-image {
            position: relative;
        }

        /* 🖼️ Encabezado con Imagen y Escudo */
        &-header {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        &-team {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            position: absolute;
            top: 1px;
            right: -100px;

            .player-page__team-name {
                font-size: 14px;
                font-weight: bold;
            }
        }

        &-name {
            font-size: 20px;
            font-weight: 500;
            color: #333;
        }

        /* 📊 Estadísticas */
        &-stats {
            margin-top: 12px;
            gap: 8px;
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    /* 📝 Cada Estadística */
    .player-page__stat-item {
        background-color: #f9fafc;
        border-radius: 6px;
        padding: 8px;
        text-align: center;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        width: 100px;

        .player-page__stat-label {
            font-size: 12px;
            color: #666;
        }

        .player-page__stat-value {
            font-size: 14px;
            font-weight: bold;
            color: #333;
        }
    }

    /* 📊 Estilos JugadorFantasyPuntuacionesComponent */

    .player-page__puntuaciones-container {
        display: flex;
        flex-direction: column;
        padding: 8px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        overflow-x: auto;
        max-width: 100%;
    }

    .player-page__puntuaciones-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 4px;
        text-align: left;
    }

    .player-page__puntuaciones-graph {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        overflow-x: auto;
        padding-bottom: 12px;
        width: 100%;
    }

    .player-page__puntuaciones-bar-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        width: 30px;
        height: 200px; // Altura fija para todas las barras
        position: relative;
        overflow: hidden;
    }

    .player-page__puntuaciones-bar {
        width: 100%;
        transition: height 1s ease-in-out;
        border-radius: 4px 4px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        overflow: hidden;
        opacity: 0; // Empieza invisible para la animación
    }

    .player-page__puntuaciones-bar.negative {
        border-radius: 0 0 4px 4px;
        height: 20px;
        margin-top: auto;
    }

    .player-page__puntuaciones-bar-text {
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        position: relative;
        z-index: 1;
    }

    .player-page__puntuaciones-bar-jornada {
        font-size: 10px;
        margin-top: 4px;
        color: #555;
        text-align: center;
    }

    /* 🎬 Animación para las barras */
    @keyframes growBar {
        0% {
            height: 0;
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}

.player-circular-indicator {
    text-align: center;
    position: relative;

    .player-circular-progress-container {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .player-circular-value {
            position: absolute;
            font-size: 14px;
            font-weight: bold;
            color: #333;
        }
    }

    .player-circular-label {
        margin-top: 8px;
        font-size: 12px;
        color: #555;
    }
}