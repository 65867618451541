.logo {
    text-transform: uppercase;
    font-size: var(--size-5);
    font-weight: bold;
    color: var(--color-grey-50);
}

@media (min-width: 768px) {
    .logo {
        font-size: var(--size-8);
    }
}
