// premium.scss
.premium-container {
    font-family: 'Arial', sans-serif;
    padding-bottom: 16px;

    h1 {
        font-size: 24px;
        margin: 0;
    }

    .premium-sections {
        display: flex;
        flex-wrap: wrap;
    }

    .premium-list {
        padding-left: 5px;
        padding-right: 5px;
        width: 33.33%;
        margin-bottom: 16px;

        @media screen and (max-width: 991px) {
            width: 50%;
        }

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
}

.premium-analisis-diario,
.premium-post-partido,
.premium-consejos {
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    h2 {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    .analisis-item,
    .post-partido-list,
    .consejos-list {
        font-size: 0.95rem;

        h3 {
            font-size: 1.2rem;
            margin: 10px 0 5px;
            color: #333;
        }

        li {
            margin-bottom: 10px;
            list-style-type: disc;
            margin-left: 20px;
        }
    }
}

.post-partido-list,
.consejos-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
        margin-bottom: 10px;

        &:last-child {
            border-bottom: none;
        }
    }
}
