@use "../shared/variables";

.virus-fifa {
    &__aditional {
        padding: 2px 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-bottom: 1px solid variables.$color-border;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    &__competition {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__eta {
        display: flex;
        flex-direction: column;
    }

    &__eta-date {
        font-size: 16px;
        margin-bottom: 0;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    &__eta-start {
        font-size: 14px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 0;
    }

    &__eta-end {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 4px;
        margin-bottom: 0;
    }

    &__competition-end {
        margin-bottom: 2px;
        display: flex;
        gap: 4px;
        align-items: center;
    }

    &__competition-line {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__convocatoria-text {
        border-radius: 2px;
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 1rem;
        gap: 4px;
    }

    &__header-text {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
    }

    &__updated-text {
        font-size: 14px;
        margin-bottom: 0;
        text-align: end;
    }

    &__player-card {
        border: 1px solid variables.$color-border;
        margin-bottom: 8px;
        border-radius: 4px;
    }

    .team-general-status-card__label {
        margin-bottom: 8px;
    }
}
