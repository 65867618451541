@use '../shared/variables';

.admin-container {
    min-height: calc(100vh - 180px);

    .mercado-container-options-select {
        margin-bottom: 25px;
        float: right;
        position: relative;
        z-index: 1;
    }

    .admin-partido {
        width: 100%;
        display: inline-flex;
        margin-bottom: 15px;
        padding: 10px 15px;
        border-radius: 2px;
        justify-content: space-between;
        border-bottom: 1px solid variables.$color-border;

        .admin-partido__line {
            width: fit-content;
        }
    }

    .admin-partido-0 {
        border-top: 1px solid variables.$color-border;
    }

    .team-image {
        width: 25px;
        height: 25px;
    }

    .team-image-visitor {
        margin-left: 7px;
    }

    .team-image-local {
        margin-right: 7px;
    }

    .admin-partido__line-separator {
        margin-left: 7px;
        margin-right: 7px;
    }

    .admin-partido__line-left {
        width: 55%;
        justify-content: space-between;
        display: inline-flex;
    }

    .admin-partido__line-right {
        width: 45%;
    }

    .update-stats-btn {
        width: 75px;
    }

    .update-stats-icon {
        font-size: 14px;
        position: relative;
        bottom: 4px;
        margin: auto;
    }

    .admin-partido__line__score {
        font-size: 20px;
        color: #0172aa;
        position: relative;
        bottom: 3px;
    }

    .update-matches-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 25px;
    }

    .update-matches-btn {
        margin-right: 0;
        font-size: 14px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .update-stats-week-btn {
        margin-right: 20px;
        font-size: 14px;
        padding-right: 20px;
        padding-left: 20px;
    }
}

.fade-loader-container {
    // width: 100%;
    // height: 100%;
    // position: absolute;
    // z-index: 9999;
    // background-color: rgba(0, 0, 0, 0.7);
    // top: 0;
    // left: 0;
    // padding-top: 46vh;
    // pointer-events: none;

    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9999999999;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .admin-container {
        padding-right: 10px;
        padding-left: 10px;

        .update-matches-btn {
            width: 100%;
        }

        .update-stats-week-btn {
            width: 100%;
            margin-bottom: 15px;
        }

        .update-matches-container {
            display: block;
        }
    }
}

.parte-medico-modal {
    width: 800px;
    max-height: 90vh;

    &__header {
        height: 50px;
    }

    &__body {
        height: calc(100% - 50px);
        overflow: auto;
    }

    @media screen and (max-width: 820px) {
        width: 90%;
    }

    &__textarea {
        width: 100%;
        min-height: 80px;
        padding-left: 8px;
        border: 1px solid variables.$color-border;
        border-radius: 4px;
        font-family: 'Roboto Condensed', 'sans-serif';

        &:focus {
            border: 1px solid variables.$color-border;
            outline: none;
        }
    }
}

.parte-medico-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100%;
    padding-top: 16px;
    padding-left: 8px !important;
    padding-right: 8px !important;

    .parte-medico-modal__body {
        width: 100%;
    }
}

#react-select-status-level-select-listbox,
#react-select-status-select-listbox {
    width: 200px !important;
    z-index: 9 !important;
}

.admin-alineaciones-probables {
    #fixture-id-select {
        display: flex;
        width: 100%;

        .fixture-id-select__mid-column {
            width: 10%;
            text-align: center;
        }

        .fixture-id-select__column-first {
            display: flex;
            gap: 10px;
            justify-content: flex-start;
            align-items: center;
            width: 45%;
        }

        .fixture-id-select__column-last {
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            align-items: center;
            width: 45%;
        }
    }
}
