@mixin center-content-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-content-column-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin start-content-column-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

@mixin end-content-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin start-content-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flexbox($justify: center, $align: center, $direction: row) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}