.price-range-container {
    @media screen and (max-width: 962px) {
        margin-bottom: 25px;
    }

    &__slider-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        margin-bottom: 5px;
        margin-top: 5px;
    }

    &__legend-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        gap: 4px;
    }

    &__select-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}
